import {Alert, Button, message, Modal} from "antd";
import * as React from "react";
import {
    CancelBookingAndGenerateReversalInvoiceComponentProps,
    GetBookingsByCourseDocument,
    GetBookinsDocument,
    GetCourseByIdDocument,
    GetSettledBookingsDocument,
    GetUnsettledBookingsDocument,
    useCancelBookingAndGenerateReversalInvoiceMutation,
} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import {TableType} from "./BookingListColumns";
import css from "./ReverseBookingModal.less";
import {GET_BOOKINS} from "../bookings.graphql";
import {
    GET_SETTLED_BOOKINGS,
    GET_UNSETTLED_BOOKINGS,
} from "../../invoice/invoice.graphql";

type ReverseBookingModalProps = {
    visible: boolean;
    closeModal: () => void;
    record: TableType;
    confirmAction?: (e: boolean) => void;
    refetch?: () => void;
};

const ReverseBookingModal: React.FC<ReverseBookingModalProps> = ({
    visible = false,
    closeModal,
    record,
    confirmAction = (e) => console.log(e),
    refetch,
}) => {
    const invoiceId = record.invoice?.id;

    const confirmDeletion = (confirmed: boolean) => {
        confirmAction(confirmed);
    };

    const refetchQueries: CancelBookingAndGenerateReversalInvoiceComponentProps["refetchQueries"] = [
        "GetSettledBookingsDocument",
        {query: GetSettledBookingsDocument},
        "GetUnsettledBookingsDocument",
        {query: GetUnsettledBookingsDocument},
        "GetBookins",
        {query: GetBookinsDocument},
        "GetBookinsQuery",
        {query: GET_BOOKINS},
        "GetUnsettledBookings",
        {query: GET_UNSETTLED_BOOKINGS},
        "GetSettledBookings",
        {query: GET_SETTLED_BOOKINGS},
    ];

    // Only pushing this for CourseDetails, because we need to refetch the bookings for the specific course
    // If we are on the course details page otherwise there is no need to refetch the bookings
    if (record.courseId) {
        console.log(
            "push to refetchQueries::: record.courseId: ",
            record.courseId,
        );
        refetchQueries.push({
            query: GetBookingsByCourseDocument,
            variables: {id: record.courseId},
        });
        refetchQueries.push({
            query: GetCourseByIdDocument,
            variables: {id: record.courseId},
        });
    }

    const [
        cancelBookingAndCreateReversal,
        {data, loading, error: cancelBookingAndCreateReversalError},
    ] = useCancelBookingAndGenerateReversalInvoiceMutation({
        awaitRefetchQueries: true,
        refetchQueries,
    });

    const handleOk = async () => {
        if (invoiceId) {
            try {
                await cancelBookingAndCreateReversal({
                    variables: {id: invoiceId, sendMail: true},
                })
                    .then(() => confirmDeletion(true))
                    .then(() => refetch?.())
                    .then(() => closeModal())
                    // .then(() => refetch?.())
                    .then(() =>
                        message.success(
                            `${i18n.containers.bookings.BookingList.bookingReversal.messageSuccess()}`,
                            3,
                        ),
                    )
                    .then(() => console.log("DEBUG 5"));
            } catch (error) {
                console.log(
                    "cancelBookingAndCreateReversal error: ",
                    cancelBookingAndCreateReversalError,
                );
                throw new Error(`Error on cancel booking: ${error}`);
            }
        }
    };

    const handleCancel = () => {
        refetch?.();
        confirmDeletion(false);
        closeModal();
    };

    return (
        <Modal
            title={i18n.containers.bookings.BookingList.bookingReversal.modalTitle()}
            // visible={visible}
            open={visible}
            onCancel={() => handleCancel()}
            destroyOnClose
            onOk={handleOk}
            // width={800}
            footer={[
                <Button
                    key="cancelReversalInvoice"
                    onClick={() => handleCancel()}
                >
                    {i18n.containers.bookings.BookingList.bookingReversal.cancel()}
                </Button>,
                <Button
                    key="okReversalInvoice"
                    type="danger"
                    onClick={handleOk}
                    // loading={cancelBookingAndCreateQueryState.loading}
                    loading={loading}
                >
                    {i18n.containers.bookings.BookingList.bookingReversal.cancelNow()}
                </Button>,
            ]}
        >
            <Alert
                // showIcon
                type="warning"
                message={
                    <div className={css.header}>
                        {i18n.containers.bookings.BookingList.bookingReversal.messageHeader()}
                    </div>
                }
                description={
                    <div className={css.content}>
                        <p>
                            {i18n.containers.bookings.BookingList.bookingReversal.messageP1()}
                        </p>
                        <p>
                            {i18n.containers.bookings.BookingList.bookingReversal.messageP2()}
                        </p>
                    </div>
                }
            />
        </Modal>
    );
};

export default React.memo(ReverseBookingModal);
