import React, {memo, FC, useState} from "react";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import {Button, Card} from "antd";
import {green, lime, magenta, orange, purple} from "@ant-design/colors";
import Page from "../../../views/Page/Page";
import Section from "../../../views/Section/Section";
import CardGrid from "./CardGrid";
import CardBookings, {cardBookings} from "../Cards/CardBookings";
import CardUpcomingCourses, {
    cardUpcomingCourses,
} from "../Cards/CardUpcomingCourses";
import CardTest from "../Cards/CardTest";
import CardCoursesWithWaitlist, {
    cardCoursesWithWaitlist,
} from "../Cards/CardCoursesWithWaitlist";

export type DashboardConfig = {
    dashColumns: number;
    cardHeight: number;
    innerCardHeight?: number;
};
const dashboardConfig: DashboardConfig = {
    dashColumns: 2,
    cardHeight: 450,
    innerCardHeight: 400,
};

export type DraggableCard = {
    id: string;
    sortIndex: number;
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    actions?: Array<React.ReactNode>;
    extra?: React.ReactNode;
};

export type DesignColor = Array<string> & {
    primary?: string | undefined;
};

type DashboardOverviewProps = {};

const DashboardOverview: FC<DashboardOverviewProps> = () => {
    const [cards, setCards] = useState<Array<DraggableCard>>([
        {
            id: "1",
            sortIndex: 1,
            title: cardBookings.title,
            content: (
                <CardBookings
                    color={orange}
                    dashboardConfig={dashboardConfig}
                />
            ),
            actions: [<Button key="deleteBtn">{"entfernen"}</Button>],
            extra: cardBookings.extra,
        },
        {
            id: "2",
            sortIndex: 2,
            title: cardUpcomingCourses.title,
            content: (
                <CardUpcomingCourses
                    color={lime}
                    dashboardConfig={dashboardConfig}
                />
            ),
        },
        {
            id: "3",
            sortIndex: 3,
            title: cardCoursesWithWaitlist.title,
            content: (
                <CardCoursesWithWaitlist
                    color={magenta}
                    dashboardConfig={dashboardConfig}
                />
            ),
            actions: [<Button key="deleteBtn">{"entfernen"}</Button>],
            extra: cardCoursesWithWaitlist.extra,
        },
        // {
        //     id: "4",
        //     sortIndex: 4,
        //     title: "hi 4",
        //     content: (
        //         <CardTest color={purple} dashboardConfig={dashboardConfig} />
        //     ),
        // },
        // {
        //     id: "5",
        //     sortIndex: 5,
        //     title: "hi 5",
        //     content: (
        //         <CardTest color={green} dashboardConfig={dashboardConfig} />
        //     ),
        // },
    ]);

    return (
        <DndProvider backend={HTML5Backend}>
            <Page
                data-testid="DashboardOverview"
                content={
                    <Section
                        showHeader={false}
                        content={
                            <CardGrid
                                cards={cards}
                                setCards={setCards}
                                dashboardConfig={dashboardConfig}
                            />
                        }
                    />
                }
            />
        </DndProvider>
    );
};

export default memo(DashboardOverview);
