import gql from "graphql-tag";

export const GET_TEMPLATES = gql`
    query GetTemplates {
        templates {
            id
            templateType
            name
            isDefault
            mailDataType
            createDate
            updateDate
        }
    }
`;

export const GET_TEMPLATES_WITH_POAGINATION = gql`
    query GetTemplatesWithPagination($options: ArrayDataOptions) {
        templatesWithPagination(options: $options) {
            existsMore
            items {
                id
                templateType
                name
                subject
                isDefault
                mailDataType
                createDate
                updateDate
            }
            total
        }
    }
`;

export const CHECK_IF_TEMPLATE_OF_TYPE_EXISTS = gql`
    query CheckIfTemplateOfTypeExists($templateType: TemplateType!) {
        checkIfTemplateOfTypeExists(templateType: $templateType)
    }
`;

export const CREATE_TEMPLATE = gql`
    mutation CreateTemplate($newTemplateData: NewTemplateInput!) {
        createTemplate(newTemplateData: $newTemplateData) {
            id
        }
    }
`;

export const DELETE_TEMPLATE = gql`
    mutation DeleteTemplate($id: String!) {
        deleteTemplate(id: $id)
    }
`;

export const GET_TEMPLATE_BY_ID = gql`
    query GetTemplateById($id: String!) {
        template(id: $id) {
            id
            name
            templateType
            headerText
            footerText
            signature
            subject
            isDefault
            includeCourseDetails
        }
    }
`;

export const UPDATE_TEMPLATE_BY_ID = gql`
    mutation UpdateTemplate(
        $editTemplateData: EditTemplateInput!
        $id: String!
    ) {
        updateTemplate(editTemplateData: $editTemplateData, id: $id) {
            id
            templateType
        }
    }
`;

export const GET_TEMPLATE_BY_TYPE = gql`
    query GetTemplateByType($templateType: TemplateType!) {
        templateByType(templateType: $templateType) {
            id
            name
            templateType
            headerText
            footerText
            signature
            subject
            includeCourseDetails
        }
    }
`;

export const SET_INCLUDE_COURSE_DETAILS = gql`
    mutation SetIncludeCourseDetails(
        $templateId: ID!
        $includeCourseDetails: Boolean!
    ) {
        setIncludeCourseDetails(
            templateId: $templateId
            includeCourseDetails: $includeCourseDetails
        ) {
            id
            includeCourseDetails
        }
    }
`;
