import React, {FC, memo, useState} from "react";
import {Button, Modal} from "antd";
import {CSVLink} from "react-csv";
import classnames from "classnames";
import moment from "moment";
import css from "./InvoiceExportModal.less";
import i18n from "../../services/i18n";
import PdfListExport from "../PdfListExport/PdfListExport";
import {CsvDataRow} from "../../helpers/tableCsvExport/getCSVData";

export type ExportInputType = "default" | "courses";

type InvoiceExportModalProps = {
    visible: boolean;
    downloadPDF: () => void;
    csvData: Array<CsvDataRow>;
    handleCancel: () => void;
    fileName?: string;
    inputType?: ExportInputType;
};

const InvoiceExportModal: FC<InvoiceExportModalProps> = ({
    visible,
    downloadPDF,
    csvData = [],
    handleCancel,
    fileName = "Export",
    inputType = "default",
    ...props
}) => {
    const [useCase, setUseCase] = useState<"preview" | "export">("preview");

    const onDownload = () => {
        setUseCase("export");
        // downloadPDF();
    };

    console.log("csvData", csvData);

    return (
        <Modal
            bodyStyle={{padding: "5px"}}
            className={classnames(css.root, css.exportInvoiceModal)}
            // visible={visible}
            open={visible}
            title={`${i18n.views.InvoiceExportModal.previewPDF()} - ${fileName}`}
            onCancel={handleCancel}
            centered={true}
            maskClosable={true}
            footer={[
                <div
                    key="exportButtons"
                    className={classnames(css.exportButtons)}
                >
                    <CSVLink
                        separator=";"
                        data={csvData}
                        filename={`${fileName}-${moment().format(
                            "YYYY-MM-DD",
                        )}.csv`}
                        target="_blank"
                    >
                        <Button className={classnames(css.export)} key="submit">
                            {i18n.views.InvoiceExportModal.downloadCSV()}
                        </Button>
                    </CSVLink>
                    <Button
                        className={classnames(css.export)}
                        key="submitCSV"
                        onClick={() => onDownload()}
                    >
                        {i18n.views.InvoiceExportModal.downloadPDF()}
                    </Button>
                </div>,
                <Button
                    className={classnames(css.cancel)}
                    key="back"
                    onClick={handleCancel}
                >
                    {i18n.views.InvoiceExportModal.closePreview()}
                </Button>,
            ]}
        >
            <h3 className={classnames(css.modalBody)}>
                <PdfListExport
                    csvData={csvData}
                    inputType={inputType}
                    fileName={fileName}
                    action={useCase}
                />
            </h3>
        </Modal>
    );
};

export default memo(InvoiceExportModal);
