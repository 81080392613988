import React, {FC, memo, useState} from "react";
import {Button, Card, List, Space} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import moment from "moment";
import {green, orange, red} from "@ant-design/colors";
import {
    DashboardConfig,
    DesignColor,
    DraggableCard,
} from "../DashboardOverview/DashboardOverview";
import {
    CourseWithPagination,
    GetCoursesWithHighDemandQuery,
    useGetCoursesWithHighDemandQuery,
} from "../../../generated/graphql";
import css from "./Cards.less";
import {
    renderCourseNumber,
    renderCourseType,
} from "../../../views/TableColumnRenderers/TableColumnRenderers";
import {URL_COURSES_DETAILS_ID} from "../../../models/url";
import WaitlistElement from "./Elements/WaitlistElement";

type CardCoursesWithWaitlistProps = {
    color: DesignColor;
    dashboardConfig: DashboardConfig;
};

const pageSize = 4;
const breakPoints = {green: 3, orange: 8, red: 10};

const CardCoursesWithWaitlist: FC<CardCoursesWithWaitlistProps> = ({
    color,
    dashboardConfig,
}) => {
    const [options, setOptions] = useState({
        limit: pageSize,
        offset: 0,
    });
    const {cardHeight, innerCardHeight} = dashboardConfig;

    const {data, loading} = useGetCoursesWithHighDemandQuery({
        variables: {options},
        // fetchPolicy: "network-only",
    });

    // type CoursesWithHighDemand = CourseWithPagination & {waitlistCount: number}
    type CoursesWithHighDemand = {
        items: GetCoursesWithHighDemandQuery["getCoursesWithHighDemand"]["items"];
        // items: Array<
        //     CourseWithPagination["items"][number] & {waitlistCount: number}
        // >;
        existsMore: CourseWithPagination["existsMore"];
        total: CourseWithPagination["total"];
    };

    const dataSource: CoursesWithHighDemand | undefined =
        data?.getCoursesWithHighDemand;

    const foundCourses = dataSource?.items?.length
        ? dataSource?.items
        : undefined;

    console.log("dataSource", dataSource);

    const titleText = `Kurse mit hoher Nachfrage`;
    const title = (
        <Space size={5} style={{fontSize: "1.3em"}}>
            {titleText}
        </Space>
    );

    const headStyle: React.CSSProperties = {
        backgroundColor: color[3],
    };

    type CardCSSProperties = React.CSSProperties & {
        "--color-1": string;
        "--color-2": string;
        "--color-3": string;
        "--color-4": string;
        "--color-5": string;
        "--inner-card-height": number;
    };

    const actualStyle: CardCSSProperties = {
        "--color-1": color[1],
        "--color-2": color[2],
        "--color-3": color[3],
        "--color-4": color[4],
        "--color-5": color[5],
        "--inner-card-height": innerCardHeight ?? 200,
    };

    const getColor = (waitListCount: number, intensity: number) => {
        if (waitListCount > breakPoints.orange) {
            return red[intensity];
        } else if (waitListCount > breakPoints.green) {
            return orange[intensity];
        }

        return green[intensity];
    };

    const ListTitle = ({
        course,
    }: {
        course: CoursesWithHighDemand["items"][number];
    }) => {
        const courseDetailsLink = URL_COURSES_DETAILS_ID;

        const courseNumber = renderCourseNumber({
            courseNumber: course.prefixedCourseNumber ?? undefined,
            courseId: course.id,
            detailsLink: courseDetailsLink,
            style: {padding: 0, marginBottom: -10},
        });

        return (
            <div className={css.title}>
                <div className={css.firstRow}>
                    <div>{`${course.location.name}`}</div>
                    <div>
                        {`${moment(course.startDateTime).format("L")} ${moment(
                            course.startDateTime,
                        ).format("LT")}`}
                    </div>
                </div>
                <div className={css.secondRow}>
                    {renderCourseType({
                        name: course.courseType.name,
                        color: course.courseType.color,
                        style: {marginLeft: -5, marginBottom: -5},
                    })}
                    {courseNumber}
                </div>
            </div>
        );
    };

    const CourseCapacity = ({
        course,
    }: {
        course: CoursesWithHighDemand["items"][number];
    }) => {
        const {maxAttendees, bookingCount} = course;

        return (
            <div>
                <WaitlistElement
                    breakPoints={breakPoints}
                    waitListCount={course.waitListCount}
                />
                <div className={css.subLine}>
                    <div />
                    <div>{`${bookingCount} von ${maxAttendees} Plätzen gebucht`}</div>
                </div>
            </div>
        );
    };

    const onChange: (page: number, pageNumber: number) => void = (
        page,
        pageNumber,
    ) => {
        const updatedOptions = {
            ...options,
            limit: pageSize,
            offset: (page > 0 ? page - 1 : 0) * pageSize,
        };

        setOptions(updatedOptions);
    };

    return (
        <Card
            size="small"
            title={title}
            hoverable
            headStyle={headStyle}
            bodyStyle={{
                ...actualStyle,
                height: innerCardHeight,
                overflow: "auto",
            }}
            style={{
                ...actualStyle,
                borderRadius: 15,
                overflow: "hidden",
                height: cardHeight,
            }}
        >
            <List
                size="small"
                className={css.coursesWithWaitlist}
                style={{
                    margin: -12,
                    height: innerCardHeight,
                    overflow: "auto",
                }}
                loading={loading}
                locale={{
                    emptyText: "Keine Kurse mit Wartelisteneinträgen gefunden",
                }}
                dataSource={dataSource?.items ?? []}
                pagination={{
                    pageSize,
                    onChange: (page, pageNumber) => {
                        onChange(page, pageNumber);
                    },
                    responsive: true,
                    total: dataSource?.total ?? 0,
                    // eslint-disable-next-line react/display-name
                    showTotal: (total, range) => {
                        return (
                            <span className={css.pagination}>{`${range[0]}-${
                                range[1]
                            } von ${dataSource?.total ?? 0} Kursen`}</span>
                        );
                    },
                    size: "small",
                    hideOnSinglePage: true,
                    style: {marginTop: -15},
                    showSizeChanger: false,
                }}
            >
                {foundCourses?.map((course) => {
                    return (
                        <List.Item
                            key={course.id}
                            style={{
                                borderLeft: `7px solid ${getColor(
                                    course.waitListCount,
                                    3,
                                )}`,
                                backgroundColor: `${getColor(
                                    course.waitListCount,
                                    0,
                                )}`,
                                marginTop: 2,
                                padding: "2px 12px",
                            }}
                        >
                            <List.Item.Meta
                                title={
                                    <div className={css.title}>
                                        <ListTitle course={course} />
                                    </div>
                                }
                                description={<CourseCapacity course={course} />}
                            />
                        </List.Item>
                    );
                })}
            </List>
        </Card>
    );
};

export const cardCoursesWithWaitlist: Pick<
    DraggableCard,
    "title" | "actions" | "extra"
> = {
    title: "Onlinebuchungen",
    extra: <Button key="reload" type="link" icon={<ReloadOutlined />} />,
};

export default memo(CardCoursesWithWaitlist);
