import {Table} from "antd";
import {ColumnProps, TablePaginationConfig} from "antd/lib/table";
import React, {FC, memo} from "react";
import {
    Course,
    CourseLesson,
    GetCourseLessonsByCourseIdQuery,
    PaymentInterval,
    useGetCourseLessonsByCourseIdQuery,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {
    renderAmount,
    renderDateAsWeekdayTag,
    renderDateFormatted,
    renderFreePlaces,
    renderStartEndTime,
} from "../TableColumnRenderers/TableColumnRenderers";
import css from "./CourseLessonTable.less";
import {sortByStartDateTime} from "../../helpers/sortingFunctions";
import {QueryItem} from "../../types";

type CourseLessonType = Pick<
    CourseLesson,
    "id" | "startDateTime" | "endDateTime" | "locationId"
>;

type CourseLessonTableProps = {
    courseId: Course["id"];
    totalPrice?: number;
    paymentInterval?: PaymentInterval;
    freePlaces?: number;
    defaultPageSize?: number;
};

type GetCourseLessonsByCourseIdQueryItem = QueryItem<
    GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]
>;

type TableType = GetCourseLessonsByCourseIdQuery["courseLessonsByCourseId"]["0"];

const CourseLessonTable: FC<CourseLessonTableProps> = ({
    courseId,
    // courseLessons,
    totalPrice,
    paymentInterval,
    freePlaces,
    defaultPageSize = 10,
}) => {
    const {data, loading} = useGetCourseLessonsByCourseIdQuery({
        variables: {courseId},
    });
    const [paginationConfig, setPaginationConfig] = React.useState<
        TablePaginationConfig
    >({
        current: 1,
        pageSize: defaultPageSize,
        total: 0,
    });
    const courseLessons: Array<GetCourseLessonsByCourseIdQueryItem> =
        data?.courseLessonsByCourseId ?? [];

    const pricePerLesson = totalPrice ? totalPrice / courseLessons.length : 0;

    const pageSize = paginationConfig.pageSize ?? 10;
    const currentPage = paginationConfig.current ?? 1;

    const columns: Array<ColumnProps<TableType>> = [
        {
            title: "#",
            dataIndex: "number",
            render: (text, record, index) =>
                index + 1 + pageSize * (currentPage - 1),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "dayOfTheWeek",
            render: (text, record) =>
                renderDateAsWeekdayTag({
                    value: record.startDateTime,
                    style: {
                        width: "100%",
                        textAlign: "center",
                        fontWeight: 600,
                    },
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "startDateTime",
            render: (text, record) =>
                renderDateFormatted(record.startDateTime, "DD. MMM YYYY"),
            // defaultSortOrder: "descend",
            sorter: sortByStartDateTime,
            sortDirections: [],
            sortOrder: "ascend",
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "times",
            render: (text, record) =>
                renderStartEndTime({
                    startTime: record.startDateTime,
                    endTime: record.endDateTime,
                }),
        },
        {
            title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
            dataIndex: "lessonPrice",
            render: (text, record) =>
                paymentInterval === PaymentInterval.PerLesson
                    ? renderAmount(pricePerLesson)
                    : null,
        },
        // {
        //     title: i18n.containers.courses.CourseCreate.courseStartDateTime.label(),
        //     dataIndex: "freePlaces",
        //     render: (text, record) => renderFreePlaces(freePlaces ?? 0),
        // },
    ];

    return (
        <div className={css.root}>
            <Table
                loading={loading}
                showHeader={false}
                size="small"
                rowKey="id"
                columns={columns}
                dataSource={courseLessons}
                pagination={
                    courseLessons.length === defaultPageSize
                        ? false
                        : paginationConfig
                }
                onChange={(pagination, filters, sorter) => {
                    setPaginationConfig(pagination);
                }}
                rowClassName={css.extraSmallRow}
            />
        </div>
    );
};

export default memo(CourseLessonTable);
