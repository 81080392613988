import {hot} from "react-hot-loader/root";
import React, {FC} from "react";
import moment from "moment";
import ApolloWrapper from "./ApolloWrapper";
import AppRoutes from "./AppRoutes";

type AppProps = {};

const App: FC<AppProps> = () => {
    moment.locale("de");

    return (
        <ApolloWrapper>
            <AppRoutes />
        </ApolloWrapper>
    );
};

export default hot(App);
