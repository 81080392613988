import React, {FC, memo} from "react";
import {Button, Card, Space} from "antd";
import {ReloadOutlined} from "@ant-design/icons";
import moment from "moment";
import {
    DashboardConfig,
    DesignColor,
    DraggableCard,
} from "../DashboardOverview/DashboardOverview";
import i18n from "../../../services/i18n";
import css from "./Cards.less";
import CardBookingsStats from "./CardBookingsStats";

type CardBookingsProps = {
    color: DesignColor;
    dashboardConfig: DashboardConfig;
};

const CardBookings: FC<CardBookingsProps> = ({color, dashboardConfig}) => {
    const {cardHeight, innerCardHeight} = dashboardConfig;
    const [timeStamp, setTimeStamp] = React.useState<number>(moment().unix());
    const actions = [
        <Button
            key="reload"
            type="link"
            icon={<ReloadOutlined />}
            // onClick={() => refetch()}
            onClick={() => setTimeStamp(moment().unix())}
        />,
    ];

    const extra = [
        <Button
            key="reload"
            type="link"
            icon={<ReloadOutlined />}
            // onClick={() => refetch()}
            onClick={() => setTimeStamp(moment().unix())}
        />,
    ];

    // const titleText = `${i18n.containers.dashboard.DashboardOverview.cards.bookings.title()}`;
    const titleText = `Onlineanmeldungen`;

    const title = (
        <Space size={5} style={{fontSize: "1.3em"}}>
            {titleText}
            {/* {extra} */}
        </Space>
    );

    const headStyle: React.CSSProperties = {
        backgroundColor: color[3],
    };

    const gridStyle: React.CSSProperties = {};

    type CardCSSProperties = React.CSSProperties & {
        "--color-1": string;
        "--color-2": string;
        "--color-3": string;
        "--color-4": string;
        "--color-5": string;
    };

    const actualStyle: CardCSSProperties = {
        "--color-1": color[1],
        "--color-2": color[2],
        "--color-3": color[3],
        "--color-4": color[4],
        "--color-5": color[5],
    };

    return (
        <Card
            size="small"
            title={title}
            extra={extra}
            actions={actions}
            hoverable
            className={css.card}
            headStyle={headStyle}
            bordered={false}
            bodyStyle={{padding: 3, height: innerCardHeight, overflow: "auto"}}
            style={{...actualStyle, height: cardHeight}}
        >
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="today"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="twentyFour"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="yesterday"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="thisWeek"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="lastWeek"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="thisMonth"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="lastMonth"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="thisYear"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
            <Card.Grid style={gridStyle} className={css.innerCard}>
                <CardBookingsStats
                    timeRangeString="all"
                    refetchTimeStamp={timeStamp}
                />
            </Card.Grid>
        </Card>
    );
};

// const MemoizedCardBookings = memo(CardBookings);

export const cardBookings: Pick<
    DraggableCard,
    "title" | "actions" | "extra"
> = {
    title: "Onlinebuchungen",
    extra: <Button key="reload" type="link" icon={<ReloadOutlined />} />,
};

export default memo(CardBookings);
