import React, {FC, memo} from "react";
import {green, orange, red} from "@ant-design/colors";
import {Tooltip} from "antd";
import css from "./WaitlistElement.less";

type WaitlistElementProps = {
    breakPoints: {
        green: number;
        orange: number;
        red: number;
    };
    waitListCount: number;
};

const WaitlistElement: FC<WaitlistElementProps> = ({
    breakPoints,
    waitListCount,
}) => {
    const colorIntensity = 4;
    const maxBars = 10;

    console.log("waitListCount", waitListCount, "breakPoints", breakPoints);

    const bars = (): Array<{count: number; color: string}> => {
        const bars = Array(waitListCount).fill(0);

        // Initial color generation based on breakpoints
        let coloredBars = bars.map((bar, index) => {
            let color = green[colorIntensity];

            if (index >= breakPoints.green) {
                color = orange[colorIntensity];
            }
            if (index >= breakPoints.orange) {
                color = red[colorIntensity];
            }

            return {count: index + 1, color};
        });

        // Fill the rest with white color if less than maxBars
        if (waitListCount < maxBars) {
            const additionalBars = Array(maxBars - waitListCount).fill({
                count: 0,
                color: "white",
            });

            coloredBars = coloredBars.concat(additionalBars);
        }

        return coloredBars;
    };

    const getFontColor = (intensity: number) => {
        console.log("waitListCount", waitListCount, "==> ", intensity);
        if (waitListCount > breakPoints.orange) {
            return red[intensity];
        } else if (waitListCount > breakPoints.green) {
            return orange[intensity];
        }

        return green[intensity];
    };

    return (
        <div className={css.root}>
            <div className={css.container}>
                <div className={css.graph}>
                    {bars().map((bar, index) => {
                        if (index < maxBars) {
                            return (
                                <div
                                    key={index}
                                    className={css.bar}
                                    style={{
                                        backgroundColor: bar.color,
                                        minWidth: "1px",
                                        flex: `calc(10 / ${maxBars})`,
                                    }}
                                />
                            );
                        }

                        return null;
                    })}
                </div>
                <div className={css.legend} style={{color: getFontColor(6)}}>
                    <Tooltip
                        title={`In diesem Kurs stehen ${waitListCount} Teilnehmer auf der Warteliste.`}
                    >{`${waitListCount} WP`}</Tooltip>
                </div>
            </div>
        </div>
    );
};

export default memo(WaitlistElement);
