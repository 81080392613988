import React, {FC, memo} from "react";
import css from "./DescriptionBox.less";

type DescriptionBoxProps = {
    headLine: string | React.ReactNode;
    content: string | React.ReactNode;
    highlighted?: string;
    style?: React.CSSProperties;
};

const DescriptionBox: FC<DescriptionBoxProps> = ({
    headLine,
    content,
    highlighted,
    style,
}) => {
    return (
        <div className={css.root} style={style}>
            <div className={css.headLine}>{headLine}</div>
            <span className={css.content}>{content}</span>
            <span className={css.highlighted}>{highlighted}</span>
        </div>
    );
};

export default memo(DescriptionBox);
