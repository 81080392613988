import {Layout} from "antd";
import Text from "antd/lib/typography/Text";
import React, {FC, memo} from "react";
import i18n from "../../services/i18n";
import css from "./LoginFooter.less";

type LoginFooterProps = {
    children?: React.ReactNode;
};

const LoginFooter: FC<LoginFooterProps> = ({children}) => {
    return (
        <Layout.Footer className={css.root}>
            <div className={css.content}>
                <Text>{i18n.containers.login.Login.footer()}</Text>
                <a href={i18n.containers.login.Login.footerEmail()}>
                    {i18n.containers.login.Login.footerEmail()}{" "}
                </a>
            </div>
            {children}
        </Layout.Footer>
    );
};

export default memo(LoginFooter);
