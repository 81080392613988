import {grey, orange, red} from "@ant-design/colors";
import {Button, Modal, Space, Tag, Tooltip} from "antd";
import moment from "moment";
import React, {FC, memo} from "react";
import {TableType} from "typeorm/metadata/types/TableTypes";
import {DeleteFilled, MoreOutlined, ReloadOutlined} from "@ant-design/icons";
import {ArrayDataOptions, Maybe} from "../../../generated/graphql";
import i18n from "../../../services/i18n";
import {marginS} from "../../../styles/layout";
import {mailjetStatusOptions} from "../../notifications/emaiNotificationStatusText";
// import {mailjetStatusOptions} from "../../notifications/SentMailsListColumns";

type ActiveFiltersProps = {
    options?: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    hiddenFilters?: Array<string>;
};

const ActiveFilters: FC<ActiveFiltersProps> = ({
    options,
    setOptions,
    refetch,
    hiddenFilters,
}) => {
    const resetFilters = (column: keyof TableType) => {
        const resetedOptions = {...options};

        const updatedFilters = options?.filter?.filter((el) => {
            return el.column !== column;
        });

        resetedOptions.filter = updatedFilters;
        // resetedOptions.filter = [];
        console.log("resetedOptions::::", resetedOptions);

        setOptions(resetedOptions);
        // refetch();
        // window.location.reload(); // THIS IS ONLY TO ENSURE THE PREVIOUS REMOVED FILTERS ARE GONE
    };

    const generalStyle: React.CSSProperties = {
        // position: "absolute",
        right: marginS,
        marginTop: `calc((${marginS} / 2 * -1) + 2px)`,
        marginLeft: "auto",
    };

    const ResetFilters = ({icon}: {icon: "delete" | " refetch"}) => {
        const defaultOptions: ArrayDataOptions = {
            filter: [],
            limit: 15,
            offset: 0,
            sortColumn: undefined,
            sortOrder: undefined,
        };

        const tooltipTitle =
            icon === "delete" ? "Alle Filter zurücksetzen" : "Liste neu laden";

        return (
            <Button
                size="small"
                type="text"
                // style={{fontSize: "0.9em", padding: 0, marginLeft: marginS}}
                style={{padding: 0, margin: 0}}
                onClick={() => {
                    setOptions(defaultOptions);
                    refetch();
                }}
            >
                <Tooltip title={tooltipTitle} placement="left">
                    {icon === "delete" ? (
                        <DeleteFilled style={{color: red[3]}} />
                    ) : (
                        <ReloadOutlined
                            style={{
                                color: orange[5],
                                fontSize: "0.9em",
                                marginLeft: 5,
                            }}
                        />
                    )}
                </Tooltip>
            </Button>
        );
    };

    const ResetLocalStorage = () => {
        const clearAppLocalStorage = (refetch: () => void) => {
            Object.keys(localStorage).forEach((key) => {
                localStorage.removeItem(key);
                console.log("==> removed:", key);
            });

            refetch();
        };

        return (
            <Button
                size="small"
                type="text"
                style={{padding: 0, margin: 0}}
                onClick={() =>
                    Modal.confirm({
                        title: "App Daten zurücksetzen",
                        content:
                            "Falls es Probleme mit der Darstellung der Inhalte von Listen gibt, können hier alle Filter in der App zurückgesetzt werden.\n Daten gehen dabei nicht verloren.",
                        okText: "Jetzt Zurücksetzen",
                        onOk: () => clearAppLocalStorage(refetch),
                        icon: null,
                    })
                }
            >
                <MoreOutlined />
            </Button>
        );
    };

    if (options?.filter?.length) {
        return (
            <div style={{minHeight: marginS}}>
                <Space style={{...generalStyle}} size={3}>
                    <div
                        style={{
                            fontSize: "0.9em",
                        }}
                    >
                        {"Aktive Filter: "}
                    </div>
                    {options.filter.map((filter) => {
                        if (
                            hiddenFilters?.includes(
                                filter.column as keyof string,
                            )
                        ) {
                            return null;
                        }

                        const col: keyof TableType = filter.column as keyof TableType;

                        const dateEntryArray = [
                            "firstCourseLesson",
                            "lastCourseLesson",
                            "dateOfBooking",
                            "paymentDue",
                            "paymentDone",
                            "payDate",
                        ];

                        const timeEntryArray = [
                            "startTimeOfLesson",
                            "endTimeOfLesson",
                        ];

                        const numberRangeEntryArray = ["credit"];

                        // console.log("col:::", col);

                        const valueToshow = () => {
                            const dateIsNotSet = (
                                date: Date | undefined | string,
                            ) => {
                                return (
                                    date === undefined ||
                                    date === null ||
                                    date.toString() === "Invalid Date" ||
                                    date === ""
                                );
                            };

                            // DATE FILTERS ---------------------------------------------------------
                            if (dateEntryArray.includes(col)) {
                                if (
                                    dateIsNotSet(filter.values[0]) &&
                                    dateIsNotSet(filter.values[1])
                                ) {
                                    return "Kein Datum gesetzt";
                                } else if (
                                    !dateIsNotSet(filter.values[0]) &&
                                    !dateIsNotSet(filter.values[1])
                                ) {
                                    return `${moment(filter.values[0]).format(
                                        "DD.MM.YY",
                                    )} - ${moment(filter.values[1]).format(
                                        "DD.MM.YY",
                                    )}`;
                                } else if (dateIsNotSet(filter.values[0])) {
                                    return `Bis einschließlich ${moment(
                                        filter.values[1],
                                    ).format("DD.MM.YY")}`;
                                }

                                return `Ab einschließlich ${moment(
                                    filter.values[0],
                                ).format("DD.MM.YY")}`;
                            }

                            // TIME FILTERS ---------------------------------------------------------
                            if (timeEntryArray.includes(col)) {
                                if (
                                    dateIsNotSet(filter.values[0]) &&
                                    dateIsNotSet(filter.values[1])
                                ) {
                                    return "Keine Uhrzeit gesetzt";
                                } else if (
                                    !dateIsNotSet(filter.values[0]) &&
                                    !dateIsNotSet(filter.values[1])
                                ) {
                                    return `${moment(filter.values[0]).format(
                                        "HH:mm",
                                    )} - ${moment(filter.values[1]).format(
                                        "HH:mm",
                                    )}`;
                                } else if (dateIsNotSet(filter.values[0])) {
                                    return `Bis ${moment(
                                        filter.values[1],
                                    ).format("HH:mm")} Uhr`;
                                }

                                return `Ab ${moment(filter.values[0]).format(
                                    "HH:mm",
                                )} Uhr`;
                            }

                            if (numberRangeEntryArray.includes(col)) {
                                if (col === "credit") {
                                    if (
                                        filter.values[0] === "0" &&
                                        filter.values[1] === "0"
                                    ) {
                                        return "Ohne Gutschrift";
                                    } else if (
                                        filter.values[0] === "1" &&
                                        filter.values[1] === "10000"
                                    ) {
                                        return "Mit Gutschrift";
                                    }

                                    return `${filter.values[0]} - ${filter.values[1]}`;
                                }

                                return `${filter.values[0]} - ${filter.values[1]}`;
                            }

                            if (col === "status") {
                                console.log("col is status");
                                // translate mailjet status
                                const status = mailjetStatusOptions.find(
                                    (el) => el.value === filter.values[0],
                                );

                                if (status) {
                                    return status.label;
                                }
                            }

                            console.log(
                                "filter.values[0]:::",
                                filter.values[0],
                            );

                            if (filter.values[0] === "true") return "Ja";
                            if (filter.values[0] === "false") return "Nein";

                            return filter.values[0];
                        };

                        return (
                            <Tag
                                key={filter.column}
                                closable
                                onClose={() => resetFilters(col)}
                                color={orange[5]}
                                style={{
                                    padding: "-5px 10px",
                                    lineHeight: "1.4",
                                    margin: 0,
                                    marginBottom: 2,
                                }}
                            >
                                {`${i18n.views.TableColumnTitles[col]()}: ${
                                    // filter.values[0]
                                    valueToshow()
                                }`}
                            </Tag>
                        );
                    })}
                    <ResetFilters icon="delete" />
                </Space>
            </div>
        );
    }

    return (
        <div style={{...generalStyle, color: grey[2], fontSize: "0.9em"}}>
            {"Keine aktiven Filter"}
            <ResetFilters icon=" refetch" />
            {/* <ResetLocalStorage /> */}
        </div>
    );
};

export default memo(ActiveFilters);
