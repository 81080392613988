import React, {FC, memo, useState} from "react";
import {Button, Modal, Radio, Space, Spin, Tooltip} from "antd";
import {
    SendOutlined,
    TableOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import {red, blue} from "@ant-design/colors";
import {
    Attendee,
    Booker,
    Booking,
    Course,
    GetTemplatesWithPaginationQuery,
    MailDataType,
    ScheduledPayment,
    TablesEnum,
    TemplateType,
    useGetTemplatesWithPaginationQuery,
} from "../../../generated/graphql";
import RenderTemplates from "./RenderTemplates";
import {marginS, panelWidth} from "../../../styles/layout";
import css from "./RenderTemplates.less";
import Section from "../../../views/Section/Section";
import i18n from "../../../services/i18n";
import {filterTemplates} from "./templateHelpers";
import Email from "./Email";
import {translatedMailDataType} from "./emailHelpers";
import {useLocalStorageOptions} from "../../../helpers/columnsLocalStorageHandler";
import {ViewType} from "../EmailTemplates";

const tablesEnum = TablesEnum.EmailTemplatePicker;

export type SelectionType =
    | Course
    | Booking
    | Booker
    | Attendee
    | ScheduledPayment;

type EmailTemplatePickerProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
    mailDataType: MailDataType;
    selection: Array<SelectionType>;
    customButtonContent?: string | React.ReactNode;
    btnClassName?: string;
};

const EmailTemplatePicker: FC<EmailTemplatePickerProps> = ({
    open,
    setOpen,
    mailDataType,
    selection,
    customButtonContent,
    btnClassName,
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState<
        GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"][0]
    >();
    const [viewType, setViewType] = useState<ViewType>("LIST");
    const [totalResults, setTotalResults] = useState<number>(0);
    const [options, setOptions] = useLocalStorageOptions({
        localStorageName: tablesEnum,
        defaultOptions: {},
        totalResults,
    });

    const {data, loading, refetch} = useGetTemplatesWithPaginationQuery({
        skip: !open,
        fetchPolicy: "network-only",
        variables: {options},
    });

    const templates:
        | GetTemplatesWithPaginationQuery["templatesWithPagination"]["items"]
        | undefined = data?.templatesWithPagination?.items;

    React.useEffect(() => {
        totalResults !== data?.templatesWithPagination?.total &&
            setTotalResults(data?.templatesWithPagination?.total ?? 0);
    }, [data, totalResults]);

    // const {data, loading} = useGetTemplatesQuery({
    //     skip: !open,
    //     fetchPolicy: "network-only",
    // });

    // const templates: GetTemplatesQuery["templates"] | undefined =
    //     data?.templates;

    if (loading) {
        return (
            <Spin
                size="small"
                style={{fontSize: "0.7em", paddingTop: 5, paddingLeft: 5}}
            />
        );
    }

    const moreThanOneCourseSelected =
        mailDataType === MailDataType.Course && selection.length > 1;

    if (!open && !selectedTemplate) {
        if (customButtonContent) {
            return <>{customButtonContent}</>;
        }

        return (
            <Tooltip
                title={
                    moreThanOneCourseSelected
                        ? "Versenden von E-Mails Nur an einen Kurs möglich"
                        : "E-Mails an Auswahl"
                }
                color={moreThanOneCourseSelected ? red[3] : undefined}
                mouseEnterDelay={0.3}
            >
                <Button
                    disabled={moreThanOneCourseSelected}
                    size="small"
                    type="link"
                    danger
                    icon={<SendOutlined />}
                    style={
                        moreThanOneCourseSelected ? undefined : {color: blue[5]}
                    }
                    onClick={() => setOpen(!open)}
                    shape="circle"
                    className={btnClassName}
                />
            </Tooltip>
        );
    }

    if (!templates) {
        return <div className={css.fullWidth}>{"Keine Vorlagen gefunden"}</div>;
    }

    const customTemplates = filterTemplates(templates, mailDataType, "custom");

    const ViewSwitch = () => {
        return (
            <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                    setViewType(e.target.value);
                }}
                value={viewType}
            >
                <Radio.Button value="GRID" checked={viewType === "GRID"}>
                    <TableOutlined
                        style={{
                            color: viewType === "GRID" ? "white" : blue[5],
                        }}
                    />
                </Radio.Button>
                <Radio.Button value="LIST" checked={viewType === "LIST"}>
                    <UnorderedListOutlined
                        style={{
                            color: viewType === "LIST" ? "white" : blue[5],
                        }}
                    />
                </Radio.Button>
            </Radio.Group>
        );
    };

    return (
        <>
            <Modal
                open={open && !selectedTemplate}
                onCancel={() => setOpen(false)}
                title={`Email an ${translatedMailDataType({
                    mailDataType,
                })} senden`}
                width={panelWidth}
                destroyOnClose={true}
                bodyStyle={{
                    overflow: "auto",
                    maxHeight: "calc(100vh - 200px)",
                    padding: "0px",
                }}
                style={{top: 40}}
                okButtonProps={{style: {display: "none"}}}
            >
                <Section
                    title={
                        <Space
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                paddingLeft: marginS,
                                paddingRight: marginS,
                            }}
                        >
                            <div>
                                {i18n.containers.templates.Templates.sectionTitle.customTemplates()}
                            </div>
                            <Space>
                                <ViewSwitch />
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        const noTemplate = templates.filter(
                                            (template) =>
                                                template.templateType ===
                                                TemplateType.NoTemplate,
                                        );

                                        setSelectedTemplate(noTemplate[0]);
                                    }}
                                >
                                    {"Email ohne Vorlage versenden"}
                                </Button>
                            </Space>
                        </Space>
                    }
                    content={
                        <RenderTemplates
                            mailDataType={mailDataType}
                            templates={customTemplates}
                            setSelectedTemplate={(open) => {
                                setSelectedTemplate(open);
                            }}
                            tablesEnum={tablesEnum}
                            options={options}
                            setOptions={setOptions}
                            refetch={refetch}
                            viewType={viewType}
                        />
                    }
                />
            </Modal>
            {selectedTemplate && (
                <Email
                    templateId={selectedTemplate.id}
                    mailDataType={mailDataType}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    selection={selection}
                    closeAll={() => {
                        setSelectedTemplate(undefined);
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default memo(EmailTemplatePicker);
