import {Button, message} from "antd";
import React, {FC} from "react";
import {SendOutlined} from "@ant-design/icons";
import {
    AttendeeOverviewGetAttendeesMinimalDocument,
    Booking,
    EditTemplateInput,
    GetBookersOverviewDocument,
    GetBookingsByCourseDocument,
    GetBookinsDocument,
    GetCoursesWithPaginationDocument,
    GetSettledBookingsDocument,
    GetUnsettledBookingsDocument,
    SendInvoicesToSelectionOfBookingInput,
    SendMailToSelectionOfAttendeesInput,
    SendMailToSelectionOfBookersInput,
    SendMailToSelectionOfBookingInput,
    SendMailToSelectionOfCourseInput,
    SendMailToSelectionOfScheduledPaymentsInput,
    useSendEmailToSelectionOfAttendeesMutation,
    useSendEmailToSelectionOfBookersMutation,
    useSendEmailToSelectionOfBookingsMutation,
    useSendEmailToSelectionOfCourseMutation,
    useSendEmailToSelectionOfScheduledPaymentsMutation,
    useSendInvoicesToSelectionOfBookingsMutation,
} from "../../../../generated/graphql";
import {SelectionType} from "../EmailTemplatePicker";
import {apolloClient} from "../../../common/ApolloWrapper";
import {KnownError, handleError} from "../../../../errors/handleErrors";

const knownErrors: Array<KnownError> = [
    {
        key: "Payload Too Large",
        title: "Der Anhang ist zu groß",
        msg:
            "Anhänge können maximal 15 MB groß sein. Es werden die Anhänge der zu sendenden Emails zusammengezählt.",
    },
    {
        key: "default",
        title: "Fehler beim Senden der Emails",
        msg:
            "Es ist ein Fehler beim Senden der Emails aufgetreten. Es wurden keine Emails versendet.",
    },
];

type NotifySelection =
    | "notifyBookings"
    | "notifyWaitlist"
    | "notifyCancelations";

type SendToCourseProps = {
    selection: Array<SelectionType>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfCourseInput["attachments"];
    disabledSendButton?: boolean;
    notifySelection?: Array<NotifySelection>;
};

export const SendToCourse: FC<SendToCourseProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
    notifySelection,
}) => {
    const sendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput = {
        courseId: selection[0].id,
        templateId: dbTemplateId ?? "",
        notifyBookings: notifySelection?.includes("notifyBookings"),
        notifyWaitlist: notifySelection?.includes("notifyWaitlist"),
        notifyCancelations: notifySelection?.includes("notifyCancelations"),
        editedTemplateInput,
        attachments,
    };

    // console.log(
    //     "sendMailToSelectionOfCourseInput",
    //     sendMailToSelectionOfCourseInput,
    // );

    const [sendMail, {loading}] = useSendEmailToSelectionOfCourseMutation({
        client: apolloClient,
    });

    const handleSend = async (
        sendMailToSelectionOfCourseInput: SendMailToSelectionOfCourseInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            await sendMail({
                variables: {sendMailToSelectionOfCourseInput},
                refetchQueries: [
                    "GetCoursesWithPagination",
                    {query: GetCoursesWithPaginationDocument},
                    "GetBookingsByCourse",
                    {
                        query: GetBookingsByCourseDocument,
                        variables: {id: selection[0].id},
                    },
                ],
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendEmailsToSelectionOfCourse?.body.Messages
                        .length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log("===> handleSend Course => sendMail error", error);
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
            // message.error({
            //     key: "sendingMails",
            //     content: `Emails konnten nicht versendet werden: ${error}`,
            //     duration: 2,
            //     then: () => {
            //         message.destroy("sendingMails");
            //     },
            // });
        } finally {
            closeAll?.();
        }
    };

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : "An Kurs senden";

    return (
        <Button
            type="primary"
            onClick={async () => handleSend(sendMailToSelectionOfCourseInput)}
            icon={<SendOutlined />}
            disabled={disabledSendButton}
            loading={loading}
        >
            {btnText}
        </Button>
    );
};

type SendToBookingProps = {
    selection: Array<Booking>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfBookingInput["attachments"];
    disabledSendButton?: boolean;
};

export const SendToBooking: FC<SendToBookingProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
}) => {
    const sendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput = {
        bookingIds: selection.map((booking) => booking.id),
        templateId: dbTemplateId ?? "",
        editedTemplateInput,
        attachments,
    };

    const [sendMail, {loading}] = useSendEmailToSelectionOfBookingsMutation({
        client: apolloClient,
    });

    const handleSend = async (
        sendMailToSelectionOfBookingInput: SendMailToSelectionOfBookingInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            const courseId =
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                selection[0].courseId ??
                selection[0].courseLessons[0].course.id ??
                "";

            await sendMail({
                variables: {sendMailToSelectionOfBookingInput},
                refetchQueries: [
                    "GetBookins",
                    {query: GetBookinsDocument},
                    "GetCoursesWithPagination",
                    {query: GetCoursesWithPaginationDocument},
                    "GetUnsettledBookings",
                    {query: GetUnsettledBookingsDocument},
                    "GetSettledBookings",
                    {query: GetSettledBookingsDocument},
                    "GetBookingsByCourse",
                    {
                        query: GetBookingsByCourseDocument,
                        variables: {id: courseId},
                    },
                ],
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendMailToSelectionOfBookings?.body?.Messages
                        ?.length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log("===> handleSend Booking => sendMail error", error);
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
            // message.error({
            //     key: "sendingMails",
            //     content: `Emails konnten nicht versendet werden: ${error}`,
            //     duration: 2,
            //     then: () => {
            //         message.destroy("sendingMails");
            //     },
            // });
        } finally {
            closeAll?.();
        }
    };

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : "An Buchungen senden";

    return (
        <Button
            type="primary"
            onClick={async () => handleSend(sendMailToSelectionOfBookingInput)}
            icon={<SendOutlined />}
            disabled={disabledSendButton}
            loading={loading}
        >
            {btnText}
        </Button>
    );
};

type SendToBookersProps = {
    selection: Array<SelectionType>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfBookingInput["attachments"];
    disabledSendButton?: boolean;
};

export const SendToBookers: FC<SendToBookersProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
}) => {
    const sendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput = {
        bookerIds: selection.map((booker) => booker.id),
        templateId: dbTemplateId ?? "",
        editedTemplateInput,
        attachments,
    };

    const [sendMail, {loading}] = useSendEmailToSelectionOfBookersMutation({
        client: apolloClient,
        refetchQueries: [
            "GetBookersOverview",
            {query: GetBookersOverviewDocument},
        ],
    });

    const handleSend = async (
        sendMailToSelectionOfBookersInput: SendMailToSelectionOfBookersInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            await sendMail({
                variables: {sendMailToSelectionOfBookersInput},
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendEmailToSelectionOfBookers?.body?.Messages
                        ?.length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log("===> handleSend Bookers=> sendMail error", error);
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
            // message.error({
            //     key: "sendingMails",
            //     content: `Emails konnten nicht versendet werden: ${error}`,
            //     duration: 2,
            //     then: () => {
            //         message.destroy("sendingMails");
            //     },
            // });
        } finally {
            closeAll?.();
        }
    };

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : "An Bucher senden";

    return (
        <Button
            type="primary"
            onClick={async () => handleSend(sendMailToSelectionOfBookersInput)}
            icon={<SendOutlined />}
            disabled={disabledSendButton}
            loading={loading}
        >
            {btnText}
        </Button>
    );
};

type SendToAttendeesProps = {
    selection: Array<SelectionType>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfBookingInput["attachments"];
    disabledSendButton?: boolean;
};

export const SendToAttendees: FC<SendToAttendeesProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
}) => {
    const sendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput = {
        attendeeIds: selection.map((attendee) => attendee.id),
        templateId: dbTemplateId ?? "",
        editedTemplateInput,
        attachments,
    };

    const [sendMail, {loading}] = useSendEmailToSelectionOfAttendeesMutation({
        client: apolloClient,
        refetchQueries: [
            "AttendeeOverviewGetAttendeesMinimal",
            {query: AttendeeOverviewGetAttendeesMinimalDocument},
        ],
    });

    const handleSend = async (
        sendMailToSelectionOfAttendeesInput: SendMailToSelectionOfAttendeesInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            await sendMail({
                variables: {sendMailToSelectionOfAttendeesInput},
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendEmailToSelectionOfAttendees?.body?.Messages
                        ?.length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log("===> handleSend Attendees => sendMail error", error);
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
            // message.error({
            //     key: "sendingMails",
            //     content: `Emails konnten nicht versendet werden: ${error}`,
            //     duration: 2,
            //     then: () => {
            //         message.destroy("sendingMails");
            //     },
            // });
        } finally {
            closeAll?.();
        }
    };

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : "An Bucher senden";

    return (
        <Button
            type="primary"
            onClick={async () =>
                handleSend(sendMailToSelectionOfAttendeesInput)
            }
            icon={<SendOutlined />}
            disabled={disabledSendButton}
            loading={loading}
        >
            {btnText}
        </Button>
    );
};

type SendToScheduledPaymentsProps = {
    selection: Array<SelectionType>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfBookingInput["attachments"];
    disabledSendButton?: boolean;
};

export const SendToScheduledPayments: FC<SendToScheduledPaymentsProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
}) => {
    const sendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput = {
        scheduledPaymentIds: selection.map(
            (scheduledPayment) => scheduledPayment.id,
        ),
        templateId: dbTemplateId ?? "",
        editedTemplateInput,
        attachments,
    };

    const [
        sendMail,
        {loading},
    ] = useSendEmailToSelectionOfScheduledPaymentsMutation({
        client: apolloClient,
    });

    const handleSend = async (
        sendMailToSelectionOfScheduledPaymentsInput: SendMailToSelectionOfScheduledPaymentsInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            await sendMail({
                variables: {sendMailToSelectionOfScheduledPaymentsInput},
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendEmailToSelectionOfScheduledPayments?.body
                        ?.Messages?.length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log(
                "===> handleSend ScheduledPayments => sendMail error",
                error,
            );
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
            // message.error({
            //     key: "sendingMails",
            //     content: `Emails konnten nicht versendet werden: ${error}`,
            //     duration: 2,
            //     then: () => {
            //         message.destroy("sendingMails");
            //     },
            // });
        } finally {
            closeAll?.();
        }
    };

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : "An Bucher des Postens senden";

    return (
        <Button
            type="primary"
            onClick={async () =>
                handleSend(sendMailToSelectionOfScheduledPaymentsInput)
            }
            icon={<SendOutlined />}
            disabled={disabledSendButton}
            loading={loading}
        >
            {btnText}
        </Button>
    );
};

type SendInvoiceToBookingsProps = {
    selection: Array<Booking>;
    dbTemplateId?: string;
    editedTemplateInput?: EditTemplateInput;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfBookingInput["attachments"];
    disabledSendButton?: boolean;
};

export const SendInvoiceToBookings: FC<SendInvoiceToBookingsProps> = ({
    selection,
    dbTemplateId,
    editedTemplateInput,
    closeAll,
    attachments,
    disabledSendButton,
}) => {
    const sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput = {
        bookingIds: selection.map((booking) => booking.id),
        reversal: false,
    };

    const [sendMail, {loading}] = useSendInvoicesToSelectionOfBookingsMutation({
        client: apolloClient,
    });

    const handleSend = async (
        sendInvoicesToSelectionOfBookingsInput: SendInvoicesToSelectionOfBookingInput,
    ) => {
        try {
            message.loading({
                key: "sendingMails",
                content: "Emails werden versendet",
            });
            const courseId =
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                selection[0].courseId ??
                selection[0].courseLessons[0].course.id ??
                "";

            await sendMail({
                variables: {sendInvoicesToSelectionOfBookingsInput},
                refetchQueries: [
                    "GetBookins",
                    {query: GetBookinsDocument},
                    "GetCoursesWithPagination",
                    {query: GetCoursesWithPaginationDocument},
                    "GetUnsettledBookings",
                    {query: GetUnsettledBookingsDocument},
                    "GetSettledBookings",
                    {query: GetSettledBookingsDocument},
                    "GetBookingsByCourse",
                    {
                        query: GetBookingsByCourseDocument,
                        variables: {id: courseId},
                    },
                ],
            }).then((res) => {
                console.log("res", res);
                const messageCount =
                    res.data?.sendInvoicesToSelectionOfBookings?.body?.Messages
                        ?.length ?? 0;

                message.success({
                    key: "sendingMails",
                    content: `${messageCount} Emails wurden versendet`,
                    duration: 2,
                    closable: true,
                    then: () => {
                        message.destroy("sendingMails");
                    },
                });
            });
        } catch (error) {
            console.log("===> handleSend Invoice => sendMail error", error);
            console.log(error);
            console.log("stringified:::", JSON.stringify(error));
            await handleError(
                "sendMailToSelectionOfCourseInput",
                error,
                "modal",
                knownErrors,
            );
        } finally {
            closeAll?.();
        }
    };

    const pluralSingular = selection.length > 1 ? "s" : "";
    const pluralSingularInvoice = selection.length > 1 ? "ungen" : "ung";

    const btnText = editedTemplateInput
        ? "Senden ohne speichern"
        : `Email${pluralSingular} mit Rechn${pluralSingularInvoice} senden`;

    return (
        <>
            <Button danger onClick={closeAll}>
                {"Nicht senden"}
            </Button>
            <Button
                type="primary"
                onClick={async () =>
                    handleSend(sendInvoicesToSelectionOfBookingsInput)
                }
                icon={<SendOutlined />}
                disabled={disabledSendButton}
                loading={loading}
            >
                {btnText}
            </Button>
        </>
    );
};
