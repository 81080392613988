import React, {FC, memo, ReactNode} from "react";
import css from "./FeatureWrapper.less";

type FeatureWrapperProps = {
    title: string;
    children?: ReactNode;
    width?: number;
    paddingTop?: number;
    height?: number;
};

const FeatureWrapper: FC<FeatureWrapperProps> = ({
    title = "Title",
    children,
    width = 900,
    paddingTop = 0,
    height,
}) => {
    return (
        <div
            className={css.root}
            style={{paddingTop, minHeight: height}}
        >
            <div className={css.title} style={{width}}>
                {title}
            </div>
            <div className={css.content} style={{maxWidth: width}}>
                {children}
            </div>
        </div>
    );
};

export default memo(FeatureWrapper);
