import React, {FC, memo, ReactNode} from "react";
import {Tabs} from "antd";
import css from "./WelcomeTabsWrapper.less";
import {featureTabs} from "../../containers/welcomePage/featureTabs.json";
import TextBlock from "../TextBlock/TextBlock";

const {TabPane} = Tabs;

type WelcomeTabsWrapperProps = {
    content: Array<any>;
};

const tabBarStyle = {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
    width: "100%",
    marginLeft: 30,
    marginRight: 30,
};

const WelcomeTabsWrapper: FC<WelcomeTabsWrapperProps> = ({
    content = [
        {
            key: "1",
            title: "Tab1",
            headline: "Headline",
            text: ["Paragraph 1", "Paragraph2"],
            features: [],
            image: "",
        },
        {
            key: "2",
            title: "Tab2",
            headline: "Headline",
            text: ["Paragraph 1", "Paragraph2"],
            features: [],
            image: "",
        },
    ],
}) => {
    return (
        <div className={css.root}>
            <Tabs size="large" tabBarStyle={tabBarStyle}>
                {content.map((tab) => {
                    return (
                        <TabPane tab={tab.title} key={tab.key}>
                            {tab.image != "" ? (
                                <div className={css.imgContainer}>
                                    <img
                                        className={css.image}
                                        src={tab.image}
                                        alt={tab.title}
                                    />
                                </div>
                            ) : null}
                            <TextBlock
                                title={tab.headLine}
                                text={tab.text}
                                width={700}
                                alignment={tab.alignment}
                                paddingTop={15}
                                children={
                                    <>
                                        <ul>
                                            {tab.features.map(
                                                (feature: Array<string>) => {
                                                    return <li>{feature}</li>;
                                                },
                                            )}
                                        </ul>
                                    </>
                                }
                            />
                        </TabPane>
                    );
                })}
            </Tabs>
        </div>
    );
};

export default memo(WelcomeTabsWrapper);
