import {FormikProps} from "formik";
import {Button, Checkbox, DatePicker, Form, Input} from "antd";
import React, {FC, memo, useEffect} from "react";
import moment from "moment";
import {blue, red} from "@ant-design/colors";
import {SaveOutlined} from "@ant-design/icons";
import {
    Booking,
    GetBookingByIdDocument,
    GetPaidScheduledPaymentsDocument,
    GetUnpaidScheduledPaymentsDocument,
    ModifyScheduledPaymentInput,
    useModifyScheduledPaymentMutation,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import LayoutForm from "../../views/LayoutForm/LayoutForm";
import {createInputHelpers} from "../../helpers/createInputHelpers";
import {isDefined} from "../../helpers/typeScriptHelpers";
import MoneyInput from "../../views/MoneyInput/MoneyInput";

type EditScheduledPaymentProps = {
    formikProps: FormikProps<ModifyScheduledPaymentInput>;
    setEditPaymentOpen: (value: boolean) => void;
    bookingId: Booking["id"];
    refetch?: () => void;
};

const EditScheduledPaymentForm: FC<EditScheduledPaymentProps> = ({
    formikProps,
    setEditPaymentOpen,
    bookingId,
    refetch,
}) => {
    const [unpaid, setUnpaid] = React.useState(false);

    const handleSetUnpaid = () => {
        formikProps.setFieldValue("paymentDone", null);
        setUnpaid(true);
        setTimeout(() => {
            setUnpaid(false);
        }, 300);
    };

    const {
        help,
        validateStatus,
        handleDatePickerChange,
        handleDatePickerBlur,
        handleBlur,
        handleNumberInputChange,
        handleCheckboxChange,
    } = createInputHelpers(formikProps);

    const {values, errors} = formikProps;

    useEffect(() => {
        if (values.paymentDone === undefined) {
            formikProps.setFieldValue("paymentDone", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values.paymentDone]);

    const refetchQueries = [
        "GetUnpaidScheduledPayments",
        {query: GetPaidScheduledPaymentsDocument},
        "GetUnpaidScheduledPayments",
        {
            query: GetUnpaidScheduledPaymentsDocument,
            // variables: {options: {}},
        },
        "GetBookingById",
        {
            query: GetBookingByIdDocument,
            variables: {id: bookingId},
        },
    ];

    const [modifyScheduledPayment] = useModifyScheduledPaymentMutation({
        refetchQueries,
        awaitRefetchQueries: true,
    });

    const handleSubmit = (values: ModifyScheduledPaymentInput) => {
        try {
            modifyScheduledPayment({
                variables: {
                    modifyScheduledPaymentInput: {
                        id: values.id,
                        paymentAmount: values.paymentAmount,
                        paymentDue: values.paymentDue,
                        paymentDone: values.paymentDone,
                        paymentMethodId: values.paymentMethodId,
                        sepaClearance: values.sepaClearance,
                    },
                },
            }).then((res) => {
                refetch?.();
                if (res.data?.modifyScheduledPayment) {
                    formikProps.resetForm();
                    setEditPaymentOpen(false);
                }
            });
        } catch (error) {
            console.log("error", error);
        } finally {
            formikProps.setSubmitting(false);
        }
    };

    return (
        <LayoutForm
            columns={5}
            onFinish={() => handleSubmit(values)}
            name="BookingForm"
        >
            <Form.Item
                label={i18n.views.TableColumnTitles.paymentDue()}
                validateStatus={validateStatus("paymentDue")}
                help={help("paymentDue")}
                required
            >
                <DatePicker
                    data-testid="paymentDue"
                    onChange={handleDatePickerChange("paymentDue")}
                    value={
                        isDefined(values.paymentDue)
                            ? moment(values.paymentDue)
                            : undefined
                    }
                    format="DD.MM.YYYY"
                    onOpenChange={handleDatePickerBlur("paymentDue")}
                    status={
                        values.paymentDue && errors.paymentDue
                            ? "error"
                            : undefined
                    }
                    style={{width: "100%"}}
                    size="small"
                />
            </Form.Item>
            <Form.Item
                label={i18n.views.TableColumnTitles.paymentAmount()}
                validateStatus={validateStatus("paymentAmount")}
                help={help("paymentAmount")}
                required
                // style={{gridColumn: "span 2"}}
                // tooltip={`This is a required field ${values.paymentAmount}}`}
            >
                <MoneyInput
                    data-testid="paymentAmount"
                    onChange={handleNumberInputChange("paymentAmount")}
                    value={
                        isDefined(values.paymentAmount)
                            ? values.paymentAmount
                            : undefined
                    }
                    onBlur={handleBlur("paymentAmount")}
                    size="small"
                />
            </Form.Item>
            <Form.Item
                label={i18n.views.TableColumnTitles.paymentDone()}
                validateStatus={validateStatus("paymentDone")}
                help={help("paymentDone")}
                tooltip={
                    values.paymentDone === null
                        ? `Um diese Zahlung als bezahlt zu markieren, muss ein Datum ausgewählt werden.`
                        : undefined
                }
            >
                <DatePicker
                    data-testid="paymentDone"
                    onChange={handleDatePickerChange("paymentDone")}
                    value={
                        isDefined(values.paymentDone)
                            ? moment(values.paymentDone)
                            : undefined
                    }
                    format="DD.MM.YYYY"
                    onOpenChange={handleDatePickerBlur("paymentDone")}
                    status={
                        values.paymentDone && errors.paymentDone
                            ? "error"
                            : undefined
                    }
                    style={{width: "100%"}}
                    defaultPickerValue={moment()}
                    open={unpaid ? false : undefined}
                    placeholder="Unbezahlt"
                    renderExtraFooter={() => {
                        if (values.paymentDone !== null) {
                            return (
                                <>
                                    <Button
                                        block
                                        size="small"
                                        style={{color: red[5]}}
                                        onClick={() => {
                                            handleSetUnpaid();
                                        }}
                                    >
                                        {"Als unbezahlt markieren"}
                                    </Button>
                                </>
                            );
                        }
                    }}
                    showNow
                    size="small"
                />
            </Form.Item>
            <Form.Item
                data-testid="sepaClearance"
                label={" "}
                validateStatus={validateStatus("sepaClearance")}
                help={help("sepaClearance")}
            >
                <Checkbox
                    onChange={handleCheckboxChange("sepaClearance")}
                    checked={Boolean(values.sepaClearance)}
                >
                    {i18n.views.TableColumnTitles.sepaClearance()}
                </Checkbox>
            </Form.Item>
            <Form.Item
                data-testid="submitButton"
                label={" "}
                // style={{width: "100%"}}
                style={{alignSelf: "flex-end"}}
            >
                <Button
                    type="primary"
                    size="small"
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    disabled={formikProps.isSubmitting}
                    block
                >
                    {"Speichern"}
                </Button>
            </Form.Item>
        </LayoutForm>
    );
};

export default memo(EditScheduledPaymentForm);
