import {green, orange, red} from "@ant-design/colors";
import classNames from "classnames";
import moment from "moment";
import React, {FC, memo} from "react";
import {Button, Space, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import NotFound from "../../containers/common/NotFound";
import {
    Course,
    GetBookingByIdQuery,
    GetCourseByIdQuery,
    PaymentInterval,
    useGetCourseByIdQuery,
} from "../../generated/graphql";
import i18n from "../../services/i18n";
import {colorBlueSlight, colorDarkGrey} from "../../styles/colors";
import LoadingContent from "../LoadingContent/LoadingContent";
import PanelCard from "../PanelCard/PanelCard";
import {
    RenderAmount,
    RenderPricePer,
    renderFirstCourseLesson,
    renderInstructors,
    renderWaitlistCount,
} from "../TableColumnRenderers/TableColumnRenderers";
import css from "./PanelCardCourseData.less";
import CourseLessonTable from "../CourseLessonTable/CourseLessonTable";
import {marginS, marginXs} from "../../styles/layout";
import AnnotationTextField from "./AnnotationTextField";

type PanelCardCourseDataProps = {
    courseId: Course["id"];
    booking?: GetBookingByIdQuery["bookingById"];
    // panels?: CourseDetailsPanels;
    overview?: boolean;
    lessons?: boolean;
    extra?: React.ReactNode;
    gapAfter?: boolean;
};

type CourseProps = {course: GetCourseByIdQuery["courseById"]};

const PanelCardCourseData: FC<PanelCardCourseDataProps> = ({
    courseId,
    booking,
    overview = true,
    lessons = false,
    extra,
    gapAfter = true,
}) => {
    const {data, loading} = useGetCourseByIdQuery({variables: {id: courseId}});

    const course: GetCourseByIdQuery["courseById"] | undefined =
        data?.courseById;

    if (course === undefined) {
        if (loading) {
            return <LoadingContent />;
        }

        return <NotFound />;
    }

    type ContactCardCSSProperties = React.CSSProperties & {
        "--column-count": number;
    };

    const actualStyle: ContactCardCSSProperties = {
        "--column-count": 1,
        // ...style,
    };

    const RenderLessons: FC<CourseProps> = ({course}) => {
        const {lessons, bookingCount, maxAttendees} = course;

        const freePlaces = maxAttendees - bookingCount;

        const color =
            freePlaces && freePlaces > 0
                ? freePlaces > 2
                    ? green[3]
                    : orange[3]
                : red[3];

        const sortedLessons = lessons.sort((a, b) => {
            return (
                new Date(a.startDateTime).getTime() -
                new Date(b.startDateTime).getTime()
            );
        });

        return (
            <>
                {sortedLessons.map((lesson) => {
                    return (
                        <div
                            style={{
                                padding: "0 4px",
                                textAlign: "center",
                                color: "#fff",
                            }}
                            key={lesson.id}
                        >
                            <div style={{backgroundColor: colorBlueSlight}}>
                                {moment(lesson.startDateTime)
                                    .locale("dddd")
                                    .format("dd")}
                            </div>
                            <div
                                style={{
                                    backgroundColor: colorBlueSlight,
                                    marginTop: 2,
                                    padding: 2,
                                }}
                            >
                                {moment(lesson.startDateTime)
                                    .locale("MMM")
                                    .format("DD.MM")}
                            </div>
                            <div
                                style={{
                                    backgroundColor: "#fff",
                                    color: colorDarkGrey,
                                }}
                            >
                                {moment(lesson.startDateTime)
                                    .locale("MMM")
                                    .format("H:mm")}
                            </div>
                            <div
                                className="free-places"
                                style={{background: color}}
                            >
                                {freePlaces > 0 ? freePlaces : 0}
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const AdditionalShortInfo = () => {
        const additionalShortInfo = course.additionalShortInfo;

        if (additionalShortInfo && additionalShortInfo.length > 0) {
            return (
                <Space className={css.additionalShortInfo} size={3}>
                    <label>{`${i18n.containers.courses.CourseCreate.additionalShortInfo.labelShort()}:`}</label>
                    <div>{additionalShortInfo}</div>
                </Space>
            );
        }

        return null;
    };

    const cardTitle = (
        <Space>
            <div>{i18n.containers.courses.CourseDetails.courseData()}</div>
            <div style={{opacity: 0.33}}>{course.prefixedCourseNumber}</div>
        </Space>
    );

    return (
        <>
            {overview ? (
                <PanelCard
                    columns={4}
                    title={cardTitle}
                    extra={
                        <>
                            <AdditionalShortInfo />
                            {extra}
                        </>
                    }
                >
                    <div
                        className={classNames(css.withDivider, css.container)}
                        style={actualStyle}
                    >
                        <div className={css.label}>
                            {i18n.containers.courses.CourseDetails.location()}
                        </div>
                        <div className={css.entry}>
                            {course.location.city}
                            <br />
                            {course.location.name}
                        </div>
                        <div className={css.label}>
                            {i18n.containers.courses.CourseDetails.coursePrice()}
                        </div>
                        <div className={css.entry}>
                            <span>
                                <RenderPricePer
                                    feeAmount={course.coursePrice[0].grossPrice}
                                    paymentInterval={course.paymentInterval}
                                />
                            </span>
                            {course.paymentInterval ===
                                PaymentInterval.PerLesson && (
                                <div
                                    style={{
                                        display: "flex",
                                        fontWeight: 650,
                                        fontSize: "0.8em",
                                    }}
                                >
                                    {"("}
                                    <RenderAmount
                                        amount={
                                            course.coursePrice[0].grossPrice *
                                            course.lessons.length
                                        }
                                        style={{
                                            fontWeight: 650,
                                            marginRight: 2,
                                            marginLeft: 1,
                                        }}
                                    />
                                    {"gesamt)"}
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        className={classNames(css.withDivider, css.container)}
                        style={actualStyle}
                    >
                        <div className={css.label}>
                            {i18n.containers.courses.CourseDetails.firstCourseLesson()}
                        </div>
                        <div className={css.entry}>
                            {renderFirstCourseLesson({
                                startEnd: {
                                    startDateTime: course.startDateTime,
                                    endDateTime:
                                        course.endDateTimeFirstCourseLesson,
                                },
                            })}
                        </div>
                        <div className={css.label}>
                            {i18n.containers.courses.CourseDetails.instructors()}
                        </div>
                        <div className={css.entry}>
                            {renderInstructors(course.instructors)}
                        </div>
                    </div>

                    <div
                        className={classNames(css.withDivider, css.container)}
                        style={actualStyle}
                    >
                        <div className={css.labelSmall}>
                            {i18n.containers.courses.CourseDetails.waitListCount()}
                        </div>
                        <div className={css.entryLarge}>
                            {renderWaitlistCount({
                                courseId: course.id,
                                kind: "waitlist",
                            })}
                        </div>
                        <div className={css.labelSmall}>
                            {i18n.containers.courses.CourseDetails.reservationCount()}
                        </div>
                        <div className={css.entryLarge}>
                            {renderWaitlistCount({
                                courseId: course.id,
                                kind: "placereservations",
                            })}
                        </div>
                        <div className={css.labelSmall}>
                            {i18n.containers.courses.CourseDetails.bookingCount()}
                        </div>
                        <div className={css.entryLarge}>
                            {renderWaitlistCount({
                                courseId: course.id,
                                kind: "bookings",
                            })}
                        </div>
                    </div>
                    <div
                        className={classNames(css.container)}
                        style={actualStyle}
                    >
                        <div className={css.labelSmall}>
                            {i18n.containers.courses.CourseDetails.maxAttendees()}
                        </div>
                        <div className={css.entryLarge}>
                            {course.maxAttendees}
                        </div>
                        <div className={css.labelSmall}>
                            {i18n.containers.courses.CourseDetails.freePlaces()}
                        </div>
                        <div
                            className={css.entryLarge}
                            style={{
                                fontSize: "4em",
                                color:
                                    course.freePlaces >= 0 ? undefined : red[4],
                            }}
                        >
                            {course.freePlaces}
                        </div>
                        {/* <div className={css.labelSmall}>{"."}</div>
                        <div className={css.entryLarge}>{"."}</div> */}
                    </div>
                </PanelCard>
            ) : null}
            {lessons ? (
                <div className={css.twoColumns}>
                    <PanelCard
                        columns={1}
                        gapAfter={gapAfter}
                        title={`${course.lessons.length} Kurseinheiten`}
                        style={{
                            overflow: "hidden",
                            height: `calc(100% - ${marginXs})`,
                        }}
                    >
                        {courseId && (
                            <div style={{marginTop: marginS}}>
                                <CourseLessonTable
                                    courseId={courseId}
                                    totalPrice={booking?.grossPrice}
                                    defaultPageSize={course.lessons.length}
                                />
                            </div>
                        )}
                    </PanelCard>
                    {/* <PanelCard
                        columns={1}
                        gapAfter
                        title={`${course.lessons.length} Kurseinheiten`}
                        style={{overflow: "hidden"}}
                    >
                        <div
                            style={{
                                display: "flex",
                                marginTop: "16px",
                                overflow: "auto",
                            }}
                        >
                            <RenderLessons course={course} />
                        </div>
                    </PanelCard> */}
                    <PanelCard
                        columns={1}
                        gapAfter={gapAfter}
                        title={i18n.containers.courses.CourseCreate.courseInfo.label()}
                        style={{
                            overflow: "hidden",
                            height: `calc(100% - ${marginXs})`,
                        }}
                        extra={
                            <Tooltip
                                title={i18n.containers.courses.CourseCreate.courseInfo.description()}
                                placement="left"
                            >
                                <Button type="link" style={{margin: 0}}>
                                    <InfoCircleOutlined />
                                </Button>
                            </Tooltip>
                        }
                    >
                        <div
                            style={{
                                paddingTop: `calc(${marginXs} + 2px)`,
                            }}
                        >
                            <AnnotationTextField
                                courseId={courseId}
                                courseLessonCount={course.lessons.length}
                                placeholder={i18n.containers.courses.CourseCreate.courseInfo.placeholder()}
                            />
                        </div>
                    </PanelCard>
                </div>
            ) : null}
        </>
    );
};

export default memo(PanelCardCourseData);
