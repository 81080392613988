import React, {FC, memo} from "react";
import Icon from "@ant-design/icons";
import {NavLink, Redirect, Route, Switch} from "react-router-dom";
import LayoutStandard from "../../views/LayoutStandard/LayoutStandard";
import {DashboardSvg} from "../../assets/SVG-Icons";
import i18n from "../../services/i18n";
import {URL_DASHBOARD} from "../../models/url";
import DashboardOverview from "./DashboardOverview/DashboardOverview";

type DashboardProps = {};

const Dashboard: FC<DashboardProps> = () => {
    return (
        <LayoutStandard
            pageTitle={(navOpen) =>
                navOpen ? (
                    <Icon component={DashboardSvg} />
                ) : (
                    // i18n.containers.dashboard.Dashboard.title()
                    <span>{"Übersicht"}</span>
                )
            }
            secondLevelNavigation={<SecondLevelNavigation />}
            main={main}
        />
    );
};

const main = (
    <Switch>
        <Route exact path={URL_DASHBOARD} component={DashboardOverview} />
        <Redirect to={URL_DASHBOARD} />
    </Switch>
);

const SecondLevelNavigation = () => {
    return (
        <>
            <NavLink to={URL_DASHBOARD}>
                {/* {i18n.containers.dashboard.DashboardOverview.title()} */}
                {"Übersicht"}
            </NavLink>
        </>
    );
};

export default memo(Dashboard);
