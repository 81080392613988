import React, {FC, memo, useEffect, useState} from "react";
import {Route, Switch, BrowserRouter as Router} from "react-router-dom";
import {useLocation, useHistory} from "react-router";
import {ConfigProvider} from "antd";
import deDE from "antd/es/locale/de_DE";
import enUS from "antd/es/locale/en_US";
import enGB from "antd/es/locale/en_GB";
import {ConfigProviderProps} from "antd/es/config-provider";
import {
    URL_HOME,
    URL_CUSTOMERS,
    URL_COURSES,
    URL_BOOKINGS,
    URL_LOCATIONS,
    URL_STAFF,
    URL_SETTINGS,
    URL_LOGIN_PARAMS,
    URL_SETUP,
    URL_WELCOME_PAGE,
    URL_INVOICE,
    URL_TEMPLATES,
    URL_PAYMENTS,
    URL_NOTIFICATIONS,
    URL_ACCOUNT,
    URL_CHANGELOG,
    URL_BADGES,
    URL_PERMISSION_DENIED,
    URL_MY_BOOKINGS,
    URL_INSTRUCTOR,
    URL_LESSONS_TODAY,
    URL_DASHBOARD,
    URL_STAFF_LOGIN,
    // URL_SETUP,
} from "../../models/url";
import * as chunks from "../chunks";
import AuthenticatedRoute from "./AuthenticatedRoute";
import WelcomePage from "../welcomePage/WelcomePage";
import {Currency, useGetAppSettingsQuery} from "../../generated/graphql"; // important!
import {routePermissions} from "./AuthenticateSetup";
import Dashboard from "../dashboard/Dashboard";

type AppRoutesProps = {};

const useResetRouterState = () => {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        history.replace(location.pathname, {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

const Routes: FC = () => {
    useResetRouterState();

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    if (isMobile) {
        return (
            <Switch>
                <Route
                    path={URL_ACCOUNT}
                    component={chunks.Account}
                    roles={routePermissions[URL_ACCOUNT]}
                />
                <Route
                    path={URL_HOME}
                    component={chunks.TopLevelPageMobile}
                    exact
                />
            </Switch>
        );
    }

    return (
        <Switch>
            <Route path={URL_HOME} component={chunks.TopLevelPage} exact />
            <AuthenticatedRoute
                path={URL_DASHBOARD}
                component={Dashboard}
                roles={routePermissions[URL_DASHBOARD]}
            />
            <AuthenticatedRoute
                path={URL_WELCOME_PAGE}
                component={WelcomePage}
                roles={routePermissions[URL_WELCOME_PAGE]}
            />
            <AuthenticatedRoute
                path={URL_SETUP}
                component={chunks.Setup}
                roles={routePermissions[URL_SETUP]}
            />
            <AuthenticatedRoute
                path={URL_COURSES}
                component={chunks.Courses}
                roles={routePermissions[URL_COURSES]}
            />
            <AuthenticatedRoute
                path={URL_CUSTOMERS}
                component={chunks.Attendees}
                roles={routePermissions[URL_CUSTOMERS]}
            />
            <AuthenticatedRoute
                path={URL_LOCATIONS}
                component={chunks.Locations}
                roles={routePermissions[URL_LOCATIONS]}
            />
            <AuthenticatedRoute
                path={URL_BOOKINGS}
                component={chunks.Bookings}
                roles={routePermissions[URL_BOOKINGS]}
            />
            <AuthenticatedRoute
                path={URL_MY_BOOKINGS}
                component={chunks.MyBookings}
                roles={routePermissions[URL_MY_BOOKINGS]}
            />
            <AuthenticatedRoute
                path={URL_INVOICE}
                component={chunks.Invoice}
                roles={routePermissions[URL_INVOICE]}
            />
            <AuthenticatedRoute
                path={URL_TEMPLATES}
                component={chunks.Templates}
                roles={routePermissions[URL_TEMPLATES]}
            />
            <AuthenticatedRoute
                path={URL_PAYMENTS}
                component={chunks.Payments}
                roles={routePermissions[URL_PAYMENTS]}
            />
            <AuthenticatedRoute
                path={URL_STAFF}
                component={chunks.Staff}
                roles={routePermissions[URL_STAFF]}
            />
            <AuthenticatedRoute
                path={URL_STAFF_LOGIN}
                component={chunks.StaffLogin}
                roles={routePermissions[URL_STAFF_LOGIN]}
            />
            <AuthenticatedRoute
                path={URL_SETTINGS}
                component={chunks.Settings}
                roles={routePermissions[URL_SETTINGS]}
            />
            <AuthenticatedRoute
                path={URL_NOTIFICATIONS}
                component={chunks.Notifications}
                roles={routePermissions[URL_NOTIFICATIONS]}
            />
            <AuthenticatedRoute
                path={URL_CHANGELOG}
                // component={chunks.ChangeLog}
                render={(props) => (
                    <chunks.ChangeLog
                        highestRole={props.match.params.highestRole}
                    />
                )}
                roles={routePermissions[URL_CHANGELOG]}
            />
            <AuthenticatedRoute
                path={URL_BADGES}
                component={chunks.Badges}
                roles={routePermissions[URL_BADGES]}
            />
            <Route
                path={URL_LOGIN_PARAMS}
                component={chunks.Login}
                roles={routePermissions[URL_LOGIN_PARAMS]}
            />
            <Route
                path={URL_ACCOUNT}
                component={chunks.Account}
                roles={routePermissions[URL_ACCOUNT]}
            />
            <Route
                path={URL_INSTRUCTOR}
                component={chunks.InstructorRouter}
                roles={routePermissions[URL_INSTRUCTOR]}
            />
            <AuthenticatedRoute
                path={URL_LESSONS_TODAY}
                component={chunks.LessonsToday}
                roles={routePermissions[URL_LESSONS_TODAY]}
            />
            <Route
                exact
                path={URL_PERMISSION_DENIED}
                component={chunks.PermissionDenied}
                roles={[]}
            />
            <AuthenticatedRoute component={chunks.NotFound} roles={[]} />
        </Switch>
    );
};

const AppRoutes: FC<AppRoutesProps> = () => {
    // TODO: ensure that tenantId exists and handle not found as defined: https://github.com/peerigon/kursorganizer-webapp/issues/339

    // ConfigProvider.config({
    //     prefixCls: "custom",
    //     theme: {
    //         primaryColor: "#CCA700",
    //     },
    // });

    const [configProvider, setConfigProvider] = useState<ConfigProviderProps>({
        locale: deDE,
        direction: "ltr",
        prefixCls: "custom",
    });

    const {data: appSettingData} = useGetAppSettingsQuery();

    // console.log("appSettingData:::", appSettingData?.settings);

    useEffect(() => {
        const getLocaleFromCurrency = (
            currency: Currency,
        ): ConfigProviderProps["locale"] => {
            switch (currency) {
                case Currency.Eur:
                    return deDE;
                case Currency.Chf:
                    return deDE;
                case Currency.Gbp:
                    return enGB;
                case Currency.Usd:
                    return enUS;
                default:
                    return deDE;
            }
        };

        if (
            appSettingData?.settings?.currency &&
            configProvider.locale !==
                getLocaleFromCurrency(appSettingData.settings.currency)
        ) {
            const updatedConfigProvider = {...configProvider};

            updatedConfigProvider.locale = getLocaleFromCurrency(
                appSettingData.settings.currency,
            );

            setConfigProvider(updatedConfigProvider);
        }
        // console.log("DID RUN::: ", appSettingData?.settings?.currency);
    }, [appSettingData, configProvider]);

    const isGerman = (langueage: string) => {
        switch (langueage) {
            case "de":
                return true;
            case "de-AT":
                return true;
            case "de-CH":
                return true;
            case "de-DE":
                return true;
            case "de-LI":
                return true;
            case "de-LU":
                return true;
            default:
                return false;
        }
    };

    return (
        <Router>
            <ConfigProvider
                // locale={configProvider.locale}
                locale={isGerman(navigator.language) ? deDE : enUS}
                direction={configProvider.direction}
            >
                <Routes />
            </ConfigProvider>
        </Router>
    );
};

export default memo(AppRoutes);
