import React, {FC, memo} from "react";
import {Button, Space} from "antd";
import {FormikProps} from "formik";
import {EditOutlined, SaveOutlined, SendOutlined} from "@ant-design/icons";
import {DbTemplate} from "./Email";
import {
    Booking,
    EditTemplateInput,
    MailDataType,
    SendMailToSelectionOfCourseInput,
    TemplateType,
} from "../../../generated/graphql";
import {SelectionType} from "./EmailTemplatePicker";
import {
    SendInvoiceToBookings,
    SendToAttendees,
    SendToBookers,
    SendToBooking,
    SendToCourse,
    SendToScheduledPayments,
} from "./SendMailHandlers/SendMailToSelectionHandlers";
import {NotifySelection} from "./SendMailHandlers/CourseBookingTypeSelector";

type EmailFooterProps = {
    dbTemplate: DbTemplate;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
    formikProps?: FormikProps<EditTemplateInput & {id: string}>;
    selection: Array<SelectionType>;
    mailDataType: MailDataType;
    closeAll?: () => void;
    attachments?: SendMailToSelectionOfCourseInput["attachments"];
    disabledSendButton?: boolean;
    notifySelection?: Array<NotifySelection>;
    setSelectedTemplateUndefined?: () => void;
};

const EmailFooter: FC<EmailFooterProps> = ({
    dbTemplate,
    editMode,
    setEditMode,
    formikProps,
    selection,
    mailDataType,
    closeAll,
    attachments,
    disabledSendButton,
    notifySelection,
    setSelectedTemplateUndefined,
}) => {
    const handleSave = () => {
        if (formikProps) {
            try {
                formikProps.submitForm();
            } catch (error) {
                console.log("===> handleSave error", error);
            }
        }
    };

    const isNoTemplate =
        dbTemplate.data?.template.templateType === TemplateType.NoTemplate;

    const SendButton = ({
        editedTemplateInput,
    }: {
        editedTemplateInput?: EditTemplateInput;
    }) => {
        switch (mailDataType) {
            case MailDataType.Course:
                return (
                    <SendToCourse
                        // selection={selection as Array<Course>}
                        selection={selection}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                        notifySelection={notifySelection}
                    />
                );
            case MailDataType.Booking:
                return (
                    <SendToBooking
                        selection={selection as Array<Booking>}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                    />
                );
            case MailDataType.Booker:
                return (
                    <SendToBookers
                        selection={selection}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                    />
                );
            case MailDataType.Attendee:
                return (
                    <SendToAttendees
                        selection={selection}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                    />
                );
            case MailDataType.ScheduledPayment:
                return (
                    <SendToScheduledPayments
                        selection={selection}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                    />
                );
            case MailDataType.Invoice:
                return (
                    <SendInvoiceToBookings
                        selection={selection as Array<Booking>}
                        dbTemplateId={dbTemplate.data?.template.id}
                        editedTemplateInput={editedTemplateInput}
                        closeAll={closeAll}
                        attachments={attachments}
                        disabledSendButton={disabledSendButton}
                    />
                );
            default:
                return (
                    <Button icon={<SendOutlined />} type="primary">
                        {"Noch nicht implementiert"}
                    </Button>
                );
        }
    };

    if (isNoTemplate) {
        setEditMode(true);
    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
            }}
        >
            {editMode ? (
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        danger
                        onClick={() => {
                            setEditMode(false);
                            if (isNoTemplate) {
                                setSelectedTemplateUndefined?.();
                            }
                        }}
                    >
                        {"Abbrechen"}
                    </Button>
                    {!isNoTemplate && (
                        <Button
                            onClick={() => handleSave()}
                            form="emailForm"
                            htmlType="submit"
                            disabled={!formikProps}
                            loading={formikProps?.isSubmitting ?? false}
                            icon={<SaveOutlined />}
                        >
                            {"Änderungen speichern"}
                        </Button>
                    )}
                    <SendButton
                        editedTemplateInput={
                            formikProps
                                ? {
                                      subject: formikProps.values.subject,
                                      headerText: formikProps.values.headerText,
                                      footerText: formikProps.values.footerText,
                                      signature: formikProps.values.signature,
                                  }
                                : undefined
                        }
                    />
                </Space>
            ) : (
                <Space
                    style={{
                        width: "100%",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        icon={<EditOutlined />}
                        onClick={() => setEditMode(true)}
                    >
                        {"Vorlage bearbeiten"}
                    </Button>
                    <SendButton />
                </Space>
            )}
        </div>
    );
};

export default memo(EmailFooter);
