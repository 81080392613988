import {Layout} from "antd";
import React, {FC, memo} from "react";
import LogoTypographic from "../../assets/logo-typographic.svg";
import css from "./LoginHeader.less";

type Link = {
    href: string;
    label: string;
};

type LoginHeaderProps = {
    link: Link;
    children?: React.ReactNode;
};

const LoginHeader: FC<LoginHeaderProps> = ({
    link = {href: "/", label: "home"},
    children,
}) => {
    return (
        <Layout.Header className={css.root}>
            <div className={css.innerHeader}>
                <a className={css.link} href={link.href}>
                    {link.label}
                </a>
                <img src={LogoTypographic} alt="logo-typographic" />
            </div>
            {children}
        </Layout.Header>
    );
};

export default memo(LoginHeader);
