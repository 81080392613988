import React, {FC, memo} from "react";
import {BookingType, MailDataType} from "../../../generated/graphql";
import {SelectionType} from "./EmailTemplatePicker";
import {NotifySelection} from "./SendMailHandlers/CourseBookingTypeSelector";
import GetReceiversBooking from "./Receivers/GetReceiversBooking";
import GetReceiversCourse from "./Receivers/GetReceiversCourse";
import GetReceiversBooker from "./Receivers/GetReceiversBooker";
import GetReceiversAttendee from "./Receivers/GetReceiversAttendee";
import GetReceiversScheduledPayment from "./Receivers/GetReceiversScheduledPayment";

type ReceiversBarProps = {
    mailDataType: MailDataType;
    selection: Array<SelectionType>;
    notifySelection?: Array<NotifySelection>;
    setDisabledSendButton?: (disabled: boolean) => void;
    setReceiverCount?: (count: number) => void;
};

export type Receiver = {
    id: string;
    email?: string;
    mobileNumber?: string;
    firstname: string;
    lastname: string;
};

export type ReceiverWithAttendeeInfo = Receiver & {
    attendee: {
        attendeeId: string;
        firstname: string;
        lastname: string;
    };
};

export type ReceiverWithBookingInfo = Receiver & {
    bookingType: BookingType;
    bookingNumberPrefix: string;
    bookingNumber: string;
    canceled: string | null;
};

const ReceiversBar: FC<ReceiversBarProps> = ({
    mailDataType,
    selection,
    notifySelection,
    setDisabledSendButton,
    setReceiverCount,
}) => {
    switch (mailDataType) {
        case MailDataType.Booking:
            return (
                <GetReceiversBooking
                    bookingIds={selection.map((s) => s.id)}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
        case MailDataType.Course:
            return (
                <GetReceiversCourse
                    selection={selection}
                    notifySelection={notifySelection}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
        case MailDataType.Booker:
            return (
                <GetReceiversBooker
                    bookers={selection}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
        case MailDataType.Attendee:
            return (
                <GetReceiversAttendee
                    attendees={selection}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
        case MailDataType.ScheduledPayment:
            return (
                <GetReceiversScheduledPayment
                    scheduledPayments={selection}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
        case MailDataType.AttendanceList:
            console.log("AttendanceList");
            break;
        case MailDataType.Invoice:
            return (
                <GetReceiversBooking
                    bookingIds={selection.map((s) => s.id)}
                    setDisabledSendButton={setDisabledSendButton}
                    setReceiverCount={setReceiverCount}
                />
            );
            break;
        case MailDataType.InvoicePdf:
            console.log("InvoicePdf");
            break;
    }

    return <div>{"Keine Empfänger gefunden"}</div>;
};

export default memo(ReceiversBar);
