import React, {FC, memo, useEffect, useState} from "react";
import {Space, Table} from "antd";
import {
    FilterValue,
    RowSelectionType,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig,
} from "antd/lib/table/interface";
import {Link} from "react-router-dom";
import {ColumnProps} from "antd/lib/table";
import Button from "../../../views/Button/Button";
import i18n from "../../../services/i18n";
import {
    URL_BOOKING_CREATE,
    URL_BOOKING_EDIT_ID,
    URL_COURSES_DETAILS_ID,
    URL_MY_BOOKINGS_CREATE,
    URL_MY_BOOKINGS_EDIT_ID,
} from "../../../models/url";
import {getPagination} from "../../../helpers/getPagination";
import {TableType, useHandleColumns} from "./BookingListColumns";
import TableToolBar, {
    BulkButtonType,
    ColumnsType,
    ReceiverType,
    SelectedRowKeysType,
    useRowSelection,
} from "../../common/tableTools/TableToolBar";
import Section from "../../../views/Section/Section";
import {
    ArrayDataOptions,
    BlankMailTypes,
    Booker,
    MailDataType,
    Maybe,
    SortOrder,
    TablesEnum,
} from "../../../generated/graphql";
import ActiveFilters from "../../common/tableTools/ActiveFilters";
import {updateFilters} from "../../../helpers/updateFilters";
import useHighestRole from "../../../helpers/useHighestRole";

// const allColumns: {
// [key in keyof TableType]: string;
export const allColumns = (): ColumnsType<TableType> => {
    const columnNames: ColumnsType<TableType> = {
        actions: i18n.views.TableColumnTitles.actions(),
        id: i18n.views.TableColumnTitles.id(),
        bookingNumber: i18n.views.TableColumnTitles.bookingNumber(),
        bookingNumberPrefix: i18n.views.TableColumnTitles.bookingNumberPrefix(),
        bookingType: i18n.views.TableColumnTitles.bookingType(),
        updateDate: i18n.views.TableColumnTitles.updateDate(),
        noticeStaff: i18n.views.TableColumnTitles.noticeStaff(),
        noticeBooker: i18n.views.TableColumnTitles.noticeBooker(),
        coursePriceId: i18n.views.TableColumnTitles.coursePriceId(),
        payDate: i18n.views.TableColumnTitles.payDate(),
        coursePrice: i18n.views.TableColumnTitles.coursePrice(),
        invoice: i18n.views.TableColumnTitles.invoice(),
        paymentType: i18n.views.TableColumnTitles.paymentType(),
        courseLessons: i18n.views.TableColumnTitles.courseLessons(),
        // attendee: i18n.views.TableColumnTitles.attendee(),
        // paymentMethodId: i18n.views.TableColumnTitles.paymentMethodId(),
        bookedBy: i18n.views.TableColumnTitles.bookedBy(),
        courseType: i18n.views.TableColumnTitles.courseType(),
        prefixedCourseNumber: i18n.views.TableColumnTitles.prefixedCourseNumber(),
        // booker: i18n.views.TableColumnTitles.booker(),
        bookerFullName: i18n.views.TableColumnTitles.bookerFullName(),
        phoneNumber: i18n.views.TableColumnTitles.phoneNumber(),
        mobileNumber: i18n.views.TableColumnTitles.mobileNumber(),
        email: i18n.views.TableColumnTitles.email(),
        attendeeFullName: i18n.views.TableColumnTitles.attendeeFullName(),
        city: i18n.views.TableColumnTitles.city(),
        location: i18n.views.TableColumnTitles.location(),
        firstCourseLesson: i18n.views.TableColumnTitles.firstCourseLesson(),
        lastCourseLesson: i18n.views.TableColumnTitles.lastCourseLesson(),
        billed: i18n.views.TableColumnTitles.billed(),
        invoiceingActions: i18n.views.TableColumnTitles.invoiceingActions(),
        grossPrice: i18n.views.TableColumnTitles.coursePrice(),
        netPrice: i18n.views.TableColumnTitles.netPrice(),
        vat: i18n.views.TableColumnTitles.vat(),
        invoiceNumber: i18n.views.TableColumnTitles.invoiceNumber(),
        isSpecial: i18n.views.TableColumnTitles.isSpecial(),
        settledActions: "",
        // reversalInvoice: i18n.views.TableColumnTitles.reversalInvoice(),
        emailNotifications: i18n.views.TableColumnTitles.emailNotifications(),
        nonDisclosureNotice: i18n.views.TableColumnTitles.nonDisclosureNotice(),
        birthday: i18n.views.TableColumnTitles.birthday(),
        age: i18n.views.TableColumnTitles.age(),
        dateOfBooking: i18n.views.TableColumnTitles.dateOfBooking(),
        createDate: i18n.views.TableColumnTitles.createDate(),
        // startDateTime: i18n.views.TableColumnTitles.startDateTimeBooking(),
        // courseId: i18n.views.TableColumnTitles.courseId(),
        rowIndex: i18n.views.TableColumnTitles.rowIndex(),
        credit: i18n.views.TableColumnTitles.bookerCredit(),
        instructors: i18n.views.TableColumnTitles.instructors(),
        lessonCount: i18n.views.TableColumnTitles.lessonCount(),
        bookingConfirmationSent: i18n.views.TableColumnTitles.bookingConfirmationSent(),
        waitingListConfirmationSent: i18n.views.TableColumnTitles.waitingListConfirmationSent(),
        invoiceSent: i18n.views.TableColumnTitles.invoiceSent(),
        cancelationSent: i18n.views.TableColumnTitles.cancelationSent(),
        paymentMethod: i18n.views.TableColumnTitles.paymentMethod(),
        additionalInfo: i18n.views.TableColumnTitles.additionalInfo(),
    };

    return columnNames;
};

const standardHiddenColumns: Array<Extract<keyof TableType, string>> = [
    "id",
    "bookingNumberPrefix",
    "coursePriceId",
    "coursePrice",
    // "paymentMethodId",
    "actions",
    "invoiceingActions",
    "settledActions",
    "invoiceingActions",
];

const mandatoryColumns: Array<Extract<keyof TableType, string>> = [
    "actions",
    "bookingNumber",
    "invoiceingActions",
];

const standardColumns: Array<Extract<keyof TableType, string>> = [
    "rowIndex",
    "bookingNumber",
    "courseType",
    "prefixedCourseNumber",
    "bookerFullName",
    "attendeeFullName",
    "city",
    "location",
    "grossPrice",
    "firstCourseLesson",
    "billed",
    "updateDate",
    "bookedBy",
    "actions",
];

export type BookingListProps = {
    style?: React.CSSProperties;
    toolBarButtons?: Array<BulkButtonType>;
    hideCreate?: boolean;
    tableTitle?: string;
    defaultPageSize?: number;
    hideHeader?: boolean;
    defaultColumns?: Array<Extract<keyof TableType, string>>;
    hiddenColumns?: Array<Extract<keyof TableType, string>>;
    actionColumn?: Function;
    tableData: {
        dataSource: Array<TableType>;
        existMore?: boolean;
        total?: number;
        loading?: boolean;
    };
    createLink?: string;
    editLink?: string;
    detailsLink?: string;
    courseDetailsLink?: string;
    specialFunctions?: Array<React.ReactNode>;
    selection?: Function;
    minPadding?: boolean;
    hidePagination?: boolean;
    selectMode?: RowSelectionType;
    hideColumnSelector?: boolean;
    preselectedId?: Maybe<string>;
    options?: Maybe<ArrayDataOptions>;
    setOptions: (options: ArrayDataOptions) => void;
    refetch: () => void;
    tablesEnum?: TablesEnum;
    // optionsCallBack?: (options: ArrayDataOptions) => void;
    notSelectable?: boolean;
    hideFiltersAndSorters?: boolean;
    scroll?: {x?: number | string; y?: number | string};
};

const BookingList: FC<BookingListProps> = ({
    style,
    toolBarButtons = [
        "columnSelector",
        "pdf",
        "eMail" /* , "bulkDelete" */,
        "sms",
    ],
    hideCreate,
    tableTitle = i18n.containers.bookings.BookingList.tableTitle(),
    defaultPageSize = 15,
    hideHeader = false,
    defaultColumns = standardColumns,
    hiddenColumns = standardHiddenColumns,
    actionColumn,
    tableData,
    createLink,
    editLink,
    detailsLink = "/",
    courseDetailsLink = URL_COURSES_DETAILS_ID,
    specialFunctions,
    selection,
    minPadding = false,
    hidePagination,
    selectMode = "checkbox",
    hideColumnSelector = false,
    preselectedId,
    options,
    setOptions,
    refetch,
    tablesEnum = TablesEnum.Bookings,
    // optionsCallBack,
    notSelectable = false,
    hideFiltersAndSorters = false,
    scroll,
}) => {
    const highestRole = useHighestRole();
    const isInstructor = highestRole === "Instructor";

    const bookingCreateURL = isInstructor
        ? URL_MY_BOOKINGS_CREATE
        : createLink ?? URL_BOOKING_CREATE;
    const bookingEditLinkURL = isInstructor
        ? URL_MY_BOOKINGS_EDIT_ID
        : editLink ?? URL_BOOKING_EDIT_ID;

    // ------------------------------ 1. Column Selector ------------------------------

    const [selectionType, setSelectionType] = useState<RowSelectionType>(
        selectMode,
    );

    // CHECK IF THERE IS A DEFAULTSELECTION GIVEN
    const [defaultSelection, setDefaultSelection] = useState<
        SelectedRowKeysType
    >(preselectedId ? [preselectedId] : []);

    const rowSelection = useRowSelection({
        type: selectionType,
        defaultSelectedKeys: defaultSelection,
    });

    useEffect(() => {
        if (selection) {
            selection(rowSelection.selectedRowKeys);
        }
    }, [rowSelection, selection]);

    useEffect(() => {
        if (preselectedId) {
            setDefaultSelection([preselectedId]);
        }
    }, [preselectedId]);

    useEffect(() => {
        // HACKY PREVENT KEEPING SELECTIONS WHEN ROW IS NOT IN LIST ANYMORE
        const bookingIds = tableData.dataSource.map((booking) => {
            return booking.id;
        });

        rowSelection.selectedRowKeys.forEach((id) => {
            // if (!bookingIds.includes(id)) {
            if (!bookingIds.some((value) => value === id)) {
                rowSelection.setSelectedRowKeys([]);
            }
        });
    }, [rowSelection, tableData.dataSource]);

    // ---------------------------------------------------------------------------------
    // HANDLE COLUMNS ---------------------------------------------------------

    const [columnsToShow, setColumnsToShow] = useState<
        Array<Extract<keyof TableType, string>>
    >(defaultColumns);

    const columns: Array<ColumnProps<TableType>> = useHandleColumns(
        columnsToShow,
        bookingEditLinkURL,
        detailsLink,
        options,
        setOptions,
        actionColumn,
        tableData.dataSource,
        options?.filter,
        rowSelection.selectedRowKeys,
        hideFiltersAndSorters,
        courseDetailsLink,
        refetch,
    );

    const onChange: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<TableType> | Array<SorterResult<TableType>>,
        extra?: TableCurrentDataSource<TableType>,
    ) => void = (pagination, filters, sorter, extra) => {
        const updatedFilters = updateFilters({
            filters,
            options,
            table: "booking",
        });

        // console.log("updatedFilters:::", updatedFilters);
        // console.groupEnd();

        const sortColumn = sorter.field;
        const sortOrder: Maybe<SortOrder> =
            sorter.order === "ascend"
                ? SortOrder.Ascending
                : sorter.order === "descend"
                ? SortOrder.Descending
                : undefined;

        const updatedOptions = {
            ...options,
            filter: updatedFilters,
            limit: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).limit,
            offset: getPagination({
                pagination,
                defaultSize: defaultPageSize,
            }).offset,
            sortColumn,
            sortOrder,
        };

        setOptions(updatedOptions);

        // if (optionsCallBack) {
        //     optionsCallBack(updatedOptions);
        // }
    };

    const renderMenu = () => {
        const menu: Array<React.ReactNode> = [];

        if (specialFunctions && rowSelection.selectedRowKeys.length > 0) {
            specialFunctions.forEach((specialFunction) =>
                menu.push(specialFunction),
            );
        }
        if (!hideCreate) {
            menu.push(
                <Button key="createButton" type="primary" size="small">
                    <Link
                        to={{
                            pathname: bookingCreateURL,
                        }}
                    >
                        {i18n.containers.bookings.BookingList.create()}
                    </Link>
                </Button>,
            );
        }

        return menu;
    };

    const getReceivers = (
        bookingIds: Array<string>,
    ): Array<ReceiverType> | undefined => {
        const bookings: Array<TableType> = tableData.dataSource.filter(
            (booking) => bookingIds.includes(booking.id),
        );

        const receivers: Array<ReceiverType> = [];

        bookings.forEach((booking) => {
            if (booking.attendee?.booker?.email) {
                receivers.push({
                    key: booking.attendee.booker.id,
                    value: booking.attendee.booker.email,
                    label: `${booking.attendee.booker.firstname} ${booking.attendee.booker.lastname}`,
                });
            }
        });

        return receivers;
    };

    const getSelection = (
        bookingIds: Array<string>,
    ): Array<Partial<Booker>> => {
        const bookings: Array<TableType> = tableData.dataSource.filter(
            (booking) => bookingIds.includes(booking.id),
        );
        const selection: Array<Partial<Booker>> = [];

        bookings.forEach((booking) => {
            if (booking?.attendee?.booker) {
                const booker = booking.attendee.booker;

                selection.push(booker);
            }
        });

        return selection;
    };

    const renderBookingsTable = () => {
        return (
            <Section
                style={style}
                title={tableTitle}
                menu={renderMenu()}
                minPadding={minPadding}
                showHeader={!hideHeader}
                secondLineLeft={
                    <Space
                        // style={{
                        //     width: "100%",
                        // }}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            padding: "2px 0px",
                        }}
                    >
                        {tableData.dataSource.length ? (
                            <TableToolBar
                                buttons={toolBarButtons}
                                columns={columns}
                                allColumns={allColumns()}
                                variableKeys={allColumns()}
                                // columnsToShow={selectedColumns}
                                columnsToShow={columnsToShow}
                                setSelectedColumns={(
                                    e: Array<keyof TableType>,
                                ) => setColumnsToShow(e)}
                                mandatoryColumns={mandatoryColumns}
                                hiddenColumns={hiddenColumns}
                                rowSelection={rowSelection}
                                selected={getSelection(
                                    rowSelection.selectedRowKeys,
                                )}
                                selectMode={(type: RowSelectionType) =>
                                    setSelectionType(type)
                                }
                                exportFileName={tableTitle}
                                dataSource={tableData.dataSource}
                                data={getReceivers(
                                    rowSelection.selectedRowKeys,
                                )}
                                type={BlankMailTypes.Booking}
                                hideColumnSelector={hideColumnSelector}
                                mailDataType={MailDataType.Booking}
                                tablesEnum={tablesEnum}
                                defaultColumns={defaultColumns as Array<string>}
                            />
                        ) : (
                            <div>{""}</div>
                        )}
                        {!hideFiltersAndSorters && (
                            <ActiveFilters
                                options={options}
                                setOptions={setOptions}
                                refetch={refetch}
                            />
                        )}
                    </Space>
                }
                content={
                    <>
                        <Table<TableType>
                            size="small"
                            showSorterTooltip={false}
                            bordered
                            columns={columns}
                            dataSource={tableData.dataSource}
                            rowKey="id"
                            loading={tableData.loading}
                            rowSelection={
                                notSelectable ? undefined : rowSelection.result
                            }
                            sticky={tableData.dataSource.length > 0}
                            scroll={
                                tableData.dataSource.length
                                    ? scroll ?? {
                                          x: `max-content`,
                                          //   y: "calc(100vh - 300px)",
                                          y: "calc(100vh - 280px)",
                                      }
                                    : {x: "auto"}
                            }
                            onChange={onChange}
                            pagination={
                                hidePagination
                                    ? false
                                    : {
                                          current:
                                              options?.offset && options?.limit
                                                  ? options.offset /
                                                        options.limit +
                                                    1
                                                  : 1,
                                          pageSize:
                                              options?.limit ?? defaultPageSize,
                                          showSizeChanger: true,
                                          pageSizeOptions: [
                                              "10",
                                              "15",
                                              "20",
                                              "25",
                                          ],
                                          defaultPageSize,
                                          total: tableData.total,
                                          hideOnSinglePage: true,
                                      }
                            }
                            // filterMultiple={false}
                            locale={{emptyText: "Keine Buchungen vorhanden"}}
                            footer={(currentPageData) =>
                                `aktive Filter: ${options?.filter?.length ??
                                    0} | ${i18n.containers.bookings.BookingList.totalCount()} ${
                                    tableData.total
                                }`
                            }
                        />
                    </>
                }
            />
        );
    };

    // return <BookingsTable dataSource={tableData.dataSource} />;
    return renderBookingsTable();
};

export default memo(BookingList);
